import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";

class CustomTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: this.props.defaultValue ? this.standardToMilitaryTime(this.props.defaultValue !== null ? this.props.defaultValue : '' ) : new Date()
    };
  }

  componentDidMount() {
    this.handleChange(this.state.startTime);
  }

  standardToMilitaryTime(time) {
    time = time.split(':')
    let min = time[1]
    let hour;
    if (min.includes('pm')) {
      hour = 12 + parseInt(time[0],10)
      min = min.toString().replace('pm','')
    } else {
      hour = time[0]
      min = min.toString().replace('am','')
    }
    const newDate = new Date();
    newDate.setFullYear(newDate.getFullYear());
    newDate.setMonth(newDate.getMonth());
    newDate.setDate(newDate.getDate());
    newDate.setHours(hour);
    newDate.setMinutes(min);
    return newDate;
  }

  handleChange = date => {
    this.setState({
      startTime: date
    });
    this.props.setPickUpTime({pickUpTime:date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).toLowerCase()});
  };

  render() {
    return (
      <DatePicker
        selected={this.state.startTime}
        onChange={this.handleChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        timeCaption="Time"
        dateFormat={["h:mm aa", "h:mmaa"]}
        className="ui fluid transparent only-bottom-border"
        id="pickUpDate"
        name="pickUpDate"
        placeholderText="Time"
      />
    );
  }
}

CustomTimePicker.propTypes = {
  defaultValue: PropTypes.string,
  setPickUpTime: PropTypes.func.isRequired
};

export default CustomTimePicker;
