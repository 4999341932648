import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Loader, Confirm } from "semantic-ui-react";
import '../styles/credit-card-brand.scss';
import '../styles/credit-card-list.scss';
import {KaptynDataService} from "../dataservices/KaptynDataService";

class PaymentCardsManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = { deleteConfirmOpen: false, selectedCard: null };
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
  }

  onSetPrimaryClick(e, cardId) {
    const userId = this.props.userId ? this.props.userId : null;
    this.props.setFormLoading({ isFormLoading: true });
    const dataService = new KaptynDataService();
    dataService.setPrimaryCard(userId, cardId).then(() => {
      this.props.setFormLoading({ isFormLoading: false });
      this.props.getPaymentCards();
    }).catch((error) => {
      const alert = {
        visible: true,
        header: 'Error',
        content: error.message,
        type: 'error',
        autoDismiss: true,
        autoDismissInterval: 5000
      };
      this.props.setAlertDetails({ alert });
      this.props.setFormLoading({ isFormLoading: false });
    });
    this.setState({ deleteConfirmOpen: false, selectedCard: null });
  }

  onDeleteClick(e, cardId) {
    this.setState({ deleteConfirmOpen: true, selectedCard: cardId });
  }

  handleDeleteConfirm() {
    const userId = this.props.userId ? this.props.userId : null;
    this.props.setFormLoading({ isFormLoading: true });
    const dataService = new KaptynDataService();
    dataService.deletePaymentCard(userId, this.state.selectedCard).then(() => {
      this.props.setFormLoading({ isFormLoading: false });
      this.props.getPaymentCards();
    }).catch((error) => {
      const alert = {
        visible: true,
        header: 'Error',
        content: error.message,
        type: 'error',
        autoDismiss: true,
        autoDismissInterval: 5000
      };
      this.props.setAlertDetails({ alert });
      this.props.setFormLoading({ isFormLoading: false });
    });
    this.setState({ deleteConfirmOpen: false, selectedCard: null });
  }

  handleDeleteCancel() {
    this.setState({ deleteConfirmOpen: false, selectedCard: null });
  }

  render() {
    let content = null;
    let paymentCards = [];
    if (this.props.loadingPaymentCards) {
      content = <Loader active inline='centered' />;
    } else {
      if (this.props.paymentCards.length > 0) {
        this.props.paymentCards.forEach((paymentCard) => {
          let brandClassName = 'card-brand';
          if (paymentCard.brand === 'American Express') {
            brandClassName += ' amex';
          } else if (paymentCard.brand === 'Visa') {
            brandClassName += ' visa';
          } else if (paymentCard.brand === 'MasterCard') {
            brandClassName += ' mastercard';
          } else if (paymentCard.brand === 'Discover') {
            brandClassName += ' discover';
          } else {
            brandClassName += ' other';
          }
          let paymentCardStatus;
          if (!paymentCard.expired && !paymentCard.failed && paymentCard.primary) {
            paymentCardStatus = 'primary-card';
          } else if (paymentCard.expired || paymentCard.failed) {
            paymentCardStatus = 'problem-with-card';
          }
          const isPrimary = !paymentCard.failed && !paymentCard.expired && paymentCard.primary;
          let cardTagSpan = null;
          if (isPrimary) {
            cardTagSpan = <span className="primary-tag">PRIMARY</span>
          } else if (!paymentCard.failed && !paymentCard.expired && paymentCard.primary) {
            cardTagSpan = <span className="declined-tag">DECLINED</span>
          } else if (paymentCard.failed && !paymentCard.expired) {
            cardTagSpan = <span className="expired-tag">EXPIRED</span>
          }
          let setPrimarySpan = null;
          if (!paymentCard.expired && !paymentCard.failed && !paymentCard.primary) {
            setPrimarySpan = <span className="set-primary"
                                   data-tip="Set as primary card"
                                   data-place="top" data-type="info"
                                   onClick={(e) => {
                                     this.onSetPrimaryClick(e, paymentCard._id);
                                   }}>
              <i className="fa fa-check"/>
            </span>;
          }
          let deleteSpan = null;
          if (!isPrimary) {
            deleteSpan = <span id={ 'del_' + paymentCard._id }
                               className="update-card"
                               data-tip="Delete card"
                               data-place="top"
                               data-type="info"
                               onClick={(e) => {
                                 this.onDeleteClick(e, paymentCard._id);
                               }}>
                      <i className="fa fa-trash-o"/>
                    </span>;
          }
          const li = <li key={ paymentCard._id } className={ paymentCardStatus }>
            <div className="credit-card-table">
              <div className='card-image'>
                <div className={ brandClassName }></div>
              </div>
              <div className='card-info'>
                <div className='card-info-brand'>
                  <span>
                    { paymentCard.number }
                  </span>
                </div>
                <div className='card-info-expiration'>
                  <span className='card-info-type-badge'>{ paymentCard.type }</span>
                  <span>Expires <strong>{ paymentCard.expiration_month } / { paymentCard.expiration_year }</strong></span>
                </div>
              </div>
              <div className='card-actions'>
                <div className="card-actions-table">
                  <div className="card-tags">
                    { cardTagSpan }
                  </div>
                  <div className="action-primary">
                    { setPrimarySpan }
                  </div>
                  <div className="action-update">
                    { deleteSpan }
                  </div>
                </div>
              </div>
            </div>
          </li>;
          paymentCards.push(li);
        });
        content = paymentCards
      } else {
        content = <li key='no-credit-cards'>
          <div className='no-credit-cards'>
            <span>No payment cards added</span>
          </div>
        </li>
      }
    }
    return (
      <div>
        <div className='dr-credit-card-list'>
          <ul className='credit-card-list'>
            { content }
          </ul>
          <ReactTooltip />
        </div>
        <Confirm
          open={ this.state.deleteConfirmOpen }
          header='Confirm'
          content='Are you sure you want to delete the selected card?'
          onCancel={ this.handleDeleteCancel }
          onConfirm={ this.handleDeleteConfirm }
          size='small'
          style={{ position: 'relative', height: 'auto' }}
        />
      </div>
    );
  }
}

PaymentCardsManager.propTypes = {
  paymentCards: PropTypes.array.isRequired,
  loadingPaymentCards: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  getPaymentCards: PropTypes.func.isRequired,
  setFormLoading: PropTypes.func.isRequired,
  setAlertDetails: PropTypes.func.isRequired
};

export default PaymentCardsManager;
