import 'cross-fetch/polyfill';
import queryString from 'query-string';
import { storeRegistry } from '../store/storeRegistry';
import {isConcierge} from "../utils/widgetUtils";


export class AbstractDataService {

  constructor() {
    if (this.apiRoot === undefined || !(typeof this.apiRoot === 'string')) {
      throw new TypeError("getter 'apiRoot' must be implemented to return a string");
    }
  }

  get requestHeaders() {
    return {
      'Cache-control': 'no-cache',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'dashride-api-key': storeRegistry.getStore().getState().widget.apiKey,
      'knxg-project' : isConcierge() ? 'embed_b2b' : 'embed_b2c'
    };
  }

  get requestMode() {
    return 'cors';
  }

  // get credentials() {
  //   return 'include';
  // }

  resolveUrl(path) {
    const cleanRoot = this.apiRoot.replace(/\/\s*$/, "");
    const cleanPath = path.replace(/^\//, "");

    return `${cleanRoot}/${cleanPath}`;
  }

  _handleStatusError(response) {
    switch (response.status) {
      case 400: {
        this._displayAlert("A bad request was made to the server "
          + "which cannot be processed (Error 400).");
        break;
      }
      case 401: {
        this._displayAlert("This client is not authorized to view the "
          + "requested resource (Error 401).");
        break;
      }
      case 403: {
        this._displayAlert("This client is forbidden to view the requested "
          + "resource (Error 403).");
        break;
      }
      case 404: {
        this._displayAlert("The requested resource could not be found by "
          + "the server (Error 404).");
        break;
      }
      case 405: {
        this._displayAlert("The requested method is not allowed by the "
          + "server for the requested resource (Error 405).");
        break;
      }
      case 406: {
        this._displayAlert("The requested resource is incapable of "
          + "generating content according to the Accept "
          + "headers in the request (Error 406).");
        break;
      }
      case 407: {
        this._displayAlert("The client must first (re-)authenticate itself "
          + "with the proxy before accessing this resource "
          + "(Error 407).");
        break;
      }
      case 408: {
        this._displayAlert("The server timed out waiting for the request"
          + " to complete (Error 408).");
        break;
      }
      case 409: {
        this._displayAlert("The server could not process the request due "
          + "to a conflict in the request (Error 409).");
        break;
      }
      case 410: {
        this._displayAlert("The requested resource is permanently "
          + "unavailable and should not be requested "
          + "again (Error 410).");
        break;
      }
      case 411: {
        this._displayAlert("The request did not specify the length of its"
          + " content, which is required to access this "
          + "resource (Error 411).");
        break;
      }
      case 412: {
        this._displayAlert("The request did not satisfy one of the "
          + "preconditions for the requested resource "
          + "(Error 412).");
        break;
      }
      case 413: {
        this._displayAlert("The requested payload is too large for the "
          + "requested resource to process (Error 413).");
        break;
      }
      case 415: {
        this._displayAlert("The requested entity has a media type which "
          + "the server or resource does not support "
          + "(Error 415).");
        break;
      }
      case 429: {
        this._displayAlert("The requesting client has sent too many "
          + "requests in a given amount of time (Error 428).");
        break;
      }
      case 500: {
        this._displayAlert("An unspecified internal server error was "
          + "encountered while attempting to process the "
          + "request (Error 500).");
        break;
      }
      case 501: {
        this._displayAlert("The request method is not implemented for "
          + "the requested resource (Error 501).");
        break;
      }
      case 502: {
        this._displayAlert("The server in its capacity as a gateway or "
          + "proxy received an invalid response from the "
          + "upstream server (Error 502).");
        break;
      }
      case 503: {
        this._displayAlert("The requested service is unavailable, usually "
          + "due to maintenance reasons (Error 503).");
        break;
      }
      case 504: {
        this._displayAlert("The server in its capacity as a gateway or "
          + "proxy did not receive a timely response "
          + "from the upstream server (Error 504).");
        break;
      }
      case 511: {
        this._displayAlert("The client needs to authenticate to gain "
          + "network access (Error 511).");
        break;
      }
      default: {
        this._displayAlert("An unspecified error was received from the "
          + "server while trying to process the request.");
        break;
      }
    }
  }


  // eslint-disable-next-line no-unused-vars
  _displayAlert(errMessage, intent = "error") {
    // console.log('>>> Alert. intent: ', intent);
    // console.log('>>> Alert. message: ', errMessage);
  }

  async _responseOk(response) {

    if (!response.ok) {
      // Report to the developer that an error occurred immediately
      console.error(`${this.constructor.name}:`, response);

      // If the server honoured our requested content-type, we can return it
      if (response.headers.get('content-type') === this.requestHeaders.Accept) {
        return response.json();

        // Error out if we fail to parse JSON data.
      } else {

        let errorMessage = `Request failed: ${response.statusText}`;
        try {
          const responseJson = await response.json();
          errorMessage = responseJson.error;
        } catch(e) {
          // Intentionally do nothing.
        }

        const ResponseError = Error;

        ResponseError.prototype.response = null;

        const responseError = new ResponseError(errorMessage);

        responseError.name = 'ResponseError';
        responseError.response = response;

        this._handleStatusError(response);
        throw responseError;
      }
    }
    const contentType = response.headers.get('content-type');

    if (!contentType) {
      return response.text();
    }

    switch (contentType.replace(/;.*/, '')) {
      case 'application/json':
      case undefined:
        return response.json();
      case 'application/octet-stream':
      case 'text/csv':
        return response.blob();
      default:
        return response.text();
    }
  }

  _responseError(error) {
    const errMsg = "A connectivity error was encountered while processing "
      + "your request.";

    console.error(`${this.constructor.name}:`, error);
    this._displayAlert(errMsg);
  }

  _fetch(method, path, data = null, headers = {}) {
    const requestUrl = this.resolveUrl(path);

    return fetch(requestUrl, {
      method,
      mode: this.requestMode,
      // credentials: this.credentials,
      headers: Object.assign({}, this.requestHeaders, headers),
      redirect: "follow",
      body: data ? data : undefined,
    });
  }

  request(method, path, data = null, headers = {}) {
    const dataObj = (data) ? JSON.stringify(data) : undefined;

    return this._fetch(method, path, dataObj, headers).then(
      (results) => this._responseOk(results),
      (error) => this._responseError(error)
    );
  }

  requestRaw(method, path, data = null, headers = {}) {
    return this._fetch(method, path, data, headers).catch(
      (error) => this._responseError(error)
    );
  }

  get(path, params, headers) {
    const paramsString = params ? queryString.stringify(params, {arrayFormat: 'bracket'}) : '';

    return this.request('GET', paramsString ? `${path}?${paramsString}`
      : path, null, headers);
  }

  getRaw(path, params, headers) {
    const paramsString = params ? queryString.stringify(params) : '';

    return this.requestRaw('GET', paramsString ? `${path}?${paramsString}`
      : path, null, headers);
  }

  post(path, data, headers) {
    return this.request('POST', path, data, headers);
  }

  put(path, data, headers) {
    return this.request('PUT', path, data, headers);
  }

  putRaw(path, data, headers) {
    return this.requestRaw('PUT', path, data, headers);
  }

  del(path, data, headers) {
    return this.request('DELETE', path, data, headers);
  }
}

