import React, { Component } from 'react';
import {Button, Header, Form, Grid, Modal, Icon, Dimmer, Loader} from 'semantic-ui-react';
import SignUp from "./SignUp";
import { KaptynDataService } from "../../dataservices/KaptynDataService";
import { setCookies } from "../../utils/cookieUtil";
import PropTypes from "prop-types";
import Alert from "../Alert";
import {PASSENGER_DETAILS, PAYMENT_DETAILS, VEHICLE_DETAILS} from "../../constants/WidgetSteps";
import * as gtmConstants from '../../constants/GtmConstants';
import {push} from '../../utils/gtmUtil';

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onContinueAsGuest = this.onContinueAsGuest.bind(this);
    this.state = {
      showLogin: this.props.modalState
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // TODO: make these validations work without jQuery!!!
    $(document).ready(()=>{
      $("#_login_form").validate({
        rules:{
          _email:{
            required:true,
            email:true
          },
          _password:{
            required:true
          }
        }
      })
    });
    if (this.props !== newProps) {
      this.setState({
        showLogin: newProps.modalState
      }, this.handleModalStateChange);
    }
  }

  handleClose = () => {
    this.props.setModalState(false);
  };

  handleModalStateChange = () => {
    if (this.state.showLogin) {
      push(gtmConstants.GTM_PAGE_LOG_IN, gtmConstants.GTM_PAGE_TITLE_LOG_IN);
    }
  };

  onSubmit(e) {
    if($("#_login_form").valid()) {
      const email = $("#_email").val();
      const password = $("#_password").val();
      this.props.setSessionLoading({ isSessionLoading: true });
      const dataService = new KaptynDataService();
      return dataService.login(email, password).then((results) => {
        if(results.user.email === email) {
          setCookies(
            results.user.id,
            results.token,
            results.user.email,
            results.user.firstName,
            results.user.lastName,
            results.user.phone);

          // If this is not the concierge scenario, use the details of the
          // current user in session as the passenger details.
          if (!this.props.isConcierge) {
            this.props.setPassengerDetails({
              name: results.user.firstName,
              lastname: results.user.lastName,
              email: results.user.email,
              phone: results.user.phoneNumbers[0].phoneNumber
            });
          }
          this.props.setSessionLoading({ isSessionLoading: false });
          this.props.setModalState(false);

          if (this.props.continueAsGuest) {
            this.props.setInitialState();
          } else if (this.props.currentStep === VEHICLE_DETAILS) {
            this.props.setCurrentStep({ currentStep: this.props.isConcierge ? PASSENGER_DETAILS : PAYMENT_DETAILS });
          }

          this.handleClose();
        }
      }).catch((error) => {
        const alert = {
          visible: true,
          header: 'Error',
          content: error.message,
          type: 'error',
          autoDismiss: true,
          autoDismissInterval: 5000
        };
        this.props.setAlertDetails({ alert });
        this.props.setSessionLoading({ isSessionLoading: false });
      });
    }
    e.preventDefault();
  }

  onContinueAsGuest() {
    this.props.setContinueAsGuest({ continueAsGuest: true });
    this.props.setCurrentStep({ currentStep: PASSENGER_DETAILS });
    this.handleClose();
  }

  render() {
    let alert = null;
    if (this.props.alertDetails.loginAlertDetails.visible) {
      alert = <Alert alertDetails={ this.props.alertDetails.loginAlertDetails } setAlertDetails={ this.props.setAlertDetails } />;
    }
    const guestText = this.props.isConcierge ? 'You can create an account now.' : 'You can create an account after booking.';
    let continueAsTextBlock = null;
    if (!this.props.isConcierge) {
      continueAsTextBlock = <div>
        <div className="col-sm-12 col-md-8">&nbsp;</div>
        <Button fluid id="continue-as-guest"
                primary color="white"
                onClick={ this.onContinueAsGuest }>
          <span>Continue as Guest</span><Icon className="arrow right" style={{ float:'right' }}/>
        </Button>
        <div className="col-sm-12 col-md-8">&nbsp;</div>
      </div>;
    }
    return (
      <div>
        <Dimmer active={ this.props.isSessionLoading } style={{ zIndex: 10000 }} inverted>
          <Loader />
        </Dimmer>
        <Modal open={ this.state.showLogin }
               onClose={ this.handleClose }
               style={{ width: '450px', height: 'auto', top: 'auto', left: 'auto', bottom: 'auto', right: 'auto' }}
               size='fullscreen'
               closeIcon
               className="col-md-6">
          <Modal.Content>
            <meta meta name="viewport" content=
              "width=device-width, user-scalable=no" />
            <Grid columns={2}>
              <Grid.Row style={{ padding: '30px' }}>
                <Grid.Column className="loginCol">
                  <Header as='h1'>
                    Welcome back!
                    <Header.Subheader style={{ marginTop: '10px', marginBottom: '10px' }}>
                      Login and speed up the checkout process and save payments to account.
                    </Header.Subheader>
                  </Header>
                  { alert }
                  <Form id="_login_form">
                    <Form.Field>
                      <input name="_email" id="_email" placeholder='Email' />
                    </Form.Field>
                    <Form.Field>
                      <input type='password' name="_password" id="_password" placeholder='Password' />
                    </Form.Field>
                    {/*<Form.Field >*/}
                    {/*  <Checkbox label='Remember me' />*/}
                    {/*  <Label as='a' id="forgot-password-label" horizontal Right>*/}
                    {/*    Forgot password?*/}
                    {/*  </Label>*/}
                    {/*</Form.Field>*/}
                    <Button fluid
                            type='submit'
                            id="submit-log-continue"
                            primary color="white"
                            onClick={ this.onSubmit }>
                            <span>Log In & Continue</span><Icon className="arrow right" style={{ float:'right' }}/>
                    </Button>
                  </Form>
                </Grid.Column>
                <Grid.Column className="registerCol">
                  <Header as='h1'>
                    Guest
                    <Header.Subheader style={{ marginTop: '10px', marginBottom: '10px' }}>
                      { guestText }
                    </Header.Subheader>
                  </Header>
                  { continueAsTextBlock }
                  <SignUp isConcierge={ this.props.isConcierge }
                          currentStep={ this.props.currentStep }
                          setCurrentStep={ this.props.setCurrentStep }
                          setPassengerPhoneNumber={ this.props.setPassengerPhoneNumber }
                          setPassengerDetails={ this.props.setPassengerDetails }
                          isSessionLoading={ this.props.isSessionLoading }
                          setSessionLoading={ this.props.setSessionLoading }
                          setModalState={ this.props.setModalState }
                          alertDetails={ this.props.alertDetails }
                          setAlertDetails={ this.props.setSignUpAlertDetails }
                          continueAsGuest={ this.props.continueAsGuest }
                          setInitialState={ this.props.setInitialState }
                          basicButton={ true } />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

Login.propTypes = {
  isConcierge: PropTypes.bool.isRequired,
  modalState: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setPassengerDetails: PropTypes.func.isRequired,
  setPassengerPhoneNumber: PropTypes.func.isRequired,
  setPaymentDetails: PropTypes.func.isRequired,
  isSessionLoading: PropTypes.bool.isRequired,
  setSessionLoading: PropTypes.func.isRequired,
  alertDetails: PropTypes.object.isRequired,
  setAlertDetails: PropTypes.func.isRequired,
  setSignUpAlertDetails: PropTypes.func.isRequired,
  setContinueAsGuest: PropTypes.func.isRequired,
  continueAsGuest: PropTypes.bool.isRequired,
  setInitialState: PropTypes.func.isRequired
};
