import React from "react";
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'

class InternationalPhoneNumber extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      value: this.props.initialValue || ''
    };
  }

  componentDidMount() {
    this.handleChange(this.state.value);
  }

  handleChange = newValue => {
    this.setState({
      value: newValue
    });
    if(newValue && isPossiblePhoneNumber(newValue)) {
      $("#phone-number-container div div input").css("border-bottom","1px solid lightgray");
      return this.props.setPassengerPhoneNumber({phone:newValue});
    }
      this.props.setPassengerPhoneNumber({phone:null});
      $("#phone-number-container div div input").css("border-bottom","1px solid #c00");
  };

  render() {
    return (
      <div>
        <PhoneInput
          placeholder="Phone Number"
          value={ this.state.value }
          onChange={ this.handleChange }
          defaultCountry="US"
          international
          required
          id="phone"
          name="phone"
        />
      </div>
    )
  }
}

InternationalPhoneNumber.propTypes = {
  initialValue: PropTypes.string,
  setPassengerPhoneNumber: PropTypes.func.isRequired,
};

export default InternationalPhoneNumber;
