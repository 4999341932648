import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon } from "semantic-ui-react";
import { PASSENGER_DETAILS } from "../../constants/WidgetSteps";

class PassengerDetailsCard extends React.Component {

  constructor(props) {
    super(props);
    this.onEditClicked = this.onEditClicked.bind(this);
  }

  onEditClicked() {
    this.props.setCurrentStep({ currentStep: PASSENGER_DETAILS });
  }

  render() {
    const description = <div style={{ padding: '10px'}}>
      <div className='row mt-4 ml-1'>
        <div className='col-sm-12 col-md-12'>
          <h2 className='ui header'>{ this.props.passengerDetails.name } {this.props.passengerDetails.lastname }</h2>
        </div>
      </div>
      <div className='row mt-4 ml-1'>
        <div className='col-sm-1 col-md-1'>
          <Icon name='envelope outline' size="large"/>
        </div>
        <div className='col-sm-10 col-md-10'>
          <p className="">{ this.props.passengerDetails.email }</p>
        </div>
      </div>
      <div className='row mt-4 ml-1'>
        <div className='col-sm-1 col-md-1'>
          <Icon name='mobile alternate' size="large"/>
        </div>
        <div className='col-sm-8 col-md-8'>
          <p className="">{ this.props.passengerDetails.phone }</p>
        </div>
      </div>
      <div className='row mt-4 ml-1'>
        <div className='col-sm-1 col-md-1'>
          <Icon name='user outline' size="large"/>
        </div>
        <div className='col-sm-8 col-md-8'>
          <p className="">{ this.props.rideDetails.numberOfPassengers } Passenger{ this.props.rideDetails.numberOfPassengers === 1 ? '' : 's' }</p>
        </div>
      </div>
      <div className='col-sm-2 col-md-2'>
        &nbsp;
      </div>
    </div>;
    const editButton = <button className="ui secondary basic button" onClick={ this.onEditClicked }>Edit</button>;
    return (
      <Card
        header='Passenger'
        description={ description }
        style={{ margin: '0 auto' }}
        extra={ editButton }
        className="passenger-card-container"
      />
    );
  }
}

PassengerDetailsCard.propTypes = {
  passengerDetails: PropTypes.object.isRequired,
  rideDetails: PropTypes.object.isRequired,
  setCurrentStep: PropTypes.func.isRequired
};

export default PassengerDetailsCard;
