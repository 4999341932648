/* eslint-disable import/no-named-as-default */
import { Route, Switch } from "react-router-dom";

import WidgetPage from "./WidgetPage";
import NotFoundPage from "./NotFoundPage";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";
import {ResetPasswordPage} from "./ResetPasswordPage";
import TagManager from 'react-gtm-module';

class App extends React.Component {
  render() {
    const tagManagerArgs = {
      gtmId: process.env.GTM_KEY
    };
    TagManager.initialize(tagManagerArgs);
    return (
      <div>
        <Switch>
          <Route exact path="/reset_password.html" component={ResetPasswordPage}/>
          <Route path="/" component={WidgetPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
