import { connect } from 'react-redux';
import {
  setApiKey,
  setIsConcierge,
  setConciergeUserId,
  setCurrentStep,
  setPickUpLocation,
  setDropOffLocation,
  setPickUpDate,
  setPickUpTime,
  setEstimatedDuration,
  setRideDetails,
  setVehicleDetails,
  getServiceLevels,
  setPassengerDetails,
  setPaymentDetails,
  getPaymentCards,
  setPassengerPhoneNumber,
  setModalState,
  setModalStateBookingConfirmed,
  setBookingConfirmedDetails,
  setFormLoading,
  setSessionLoading,
  setInitialState,
  setFormAlertDetails,
  setLoginAlertDetails,
  setSignUpAlertDetails,
  setContinueAsGuest,
  setGuestUserId
} from "../actions/WidgetActions";


function mapStateToProps(state) {
  const s = state.widget || state;
  return {
    currentStep: s.currentStep,
    isConcierge: s.isConcierge,
    conciergeUserId: s.conciergeUserId,
    isFormLoading: s.isFormLoading,
    isSessionLoading: s.isSessionLoading,
    rideDetails: s.rideDetails,
    vehicleDetails: s.vehicleDetails,
    passengerDetails: s.passengerDetails,
    paymentDetails: s.paymentDetails,
    modalState: s.modalState,
    modalStateBookingConfirmed: s.modalStateBookingConfirmed,
    bookingConfirmedDetails: s.bookingConfirmedDetails,
    tempDetails: s.tempDetails,
    alertDetails: s.alertDetails,
    continueAsGuest: s.continueAsGuest,
    guestUserId: s.guestUserId,
    map: s.map
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setApiKey: (apiKey) => dispatch(setApiKey(apiKey)),
    setIsConcierge: (isConcierge) => dispatch(setIsConcierge(isConcierge)),
    setConciergeUserId: (conciergeUserId) => dispatch(setConciergeUserId(conciergeUserId)),
    setCurrentStep: (currentStep) => dispatch(setCurrentStep(currentStep)),
    setPickUpLocation: (pickUpLocation) => dispatch(setPickUpLocation(pickUpLocation)),
    setDropOffLocation: (dropOffLocation) => dispatch(setDropOffLocation(dropOffLocation)),
    setPickUpDate: (pickUpDate) => dispatch(setPickUpDate(pickUpDate)),
    setPickUpTime: (pickUpTime) => dispatch(setPickUpTime(pickUpTime)),
    setEstimatedDuration: (estimatedDuration) => dispatch(setEstimatedDuration(estimatedDuration)),
    setRideDetails: (rideDetails) => dispatch(setRideDetails(rideDetails)),
    setVehicleDetails: (vehicleDetails) => dispatch(setVehicleDetails(vehicleDetails)),
    getServiceLevels: () => dispatch(getServiceLevels()),
    setPassengerDetails: (passengerDetails) => dispatch(setPassengerDetails(passengerDetails)),
    setPaymentDetails: (paymentDetails) => dispatch(setPaymentDetails(paymentDetails)),
    getPaymentCards: (userId) => dispatch(getPaymentCards(userId)),
    setPassengerPhoneNumber: (passengerPhoneNumber) => dispatch(setPassengerPhoneNumber(passengerPhoneNumber)),
    setModalState: (modalState) => dispatch(setModalState(modalState)),
    setModalStateBookingConfirmed: (modalStateBookingConfirmed) => dispatch(setModalStateBookingConfirmed(modalStateBookingConfirmed)),
    setBookingConfirmedDetails: (bookingConfirmedDetails) => dispatch(setBookingConfirmedDetails(bookingConfirmedDetails)),
    setFormLoading: (isFormLoading) => dispatch(setFormLoading(isFormLoading)),
    setSessionLoading: (isSessionLoading) => dispatch(setSessionLoading(isSessionLoading)),
    setInitialState: () => dispatch(setInitialState()),
    setFormAlertDetails: (formAlertDetails) => dispatch(setFormAlertDetails(formAlertDetails)),
    setLoginAlertDetails: (loginAlertDetails) => dispatch(setLoginAlertDetails(loginAlertDetails)),
    setSignUpAlertDetails: (signUpAlertDetails) => dispatch(setSignUpAlertDetails(signUpAlertDetails)),
    setContinueAsGuest: (continueAsGuest) => dispatch(setContinueAsGuest(continueAsGuest)),
    setGuestUserId: (guestUserId) => dispatch(setGuestUserId(guestUserId))
  };
}

export default function createConnection() {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  );
}
