// Colors.
export const PRIMARY_RED = "#b00020";
export const PRIMARY_GREEN = "#24a249";
export const PRIMARY_WHITE = "#ffffff";
export const PRIMARY_ORANGE = "#f0ad4e";

// Flight status
export const FLIGHT_STATUS_CANCELED = 'canceled';
export const FLIGHT_STATUS_SCHEDULED = 'scheduled';
export const FLIGHT_STATUS_DELAYED = 'delayed';
export const FLIGHT_STATUS_REROUTED = 'rerouted';
export const FLIGHT_STATUS_ERROR = 'error';
export const FLIGHT_STATUS_LANDED = 'landed';

// Flight messages
export const FLIGHT_STATUS_NOT_FOUND = 'No flight information on this date.';
