import { DESTINATION } from '../constants/RideTypes'
import { RIDE_DETAILS } from '../constants/WidgetSteps'

const initialState = {
  apiKey: null,
  isConcierge: false,
  conciergeUserId: null,
  currentStep: RIDE_DETAILS,
  continueAsGuest: false,
  guestUserId: null,
  modalState: false,
  modalStateBookingConfirmed: false,
  isFormLoading: false,
  isSessionLoading: false,
  rideDetails: {
    rideType: DESTINATION,
    numberOfPassengers: 1,
    numberOfBags: 0,
    fromAddress: '',
    fromCoordinates: [],
    toAddress: '',
    toCoordinates: [],
    pickUpDate: null,
    pickUpTime: null,
    duration: null,
    estimatedDuration: null,
    flight: {
      number: '',
      airline: '',
      carrier: '',
      airportChecked: false
    }
  },
  vehicleDetails: {
    serviceLevels: [],
    serviceLevel: null,
    group: null,
    rate: null,
    name: null,
    description: null
  },
  passengerDetails: {
    company: '',
    name: '',
    lastname: '',
    email: '',
    phone: null
  },
  paymentDetails: {
    cardHolder: '',
    number: '',
    expMonth: '',
    expYear: '',
    cvv: '',
    loadingPaymentCards: false,
    paymentCards: []
  },
  bookingConfirmedDetails: {
    scheduledDate:'',
    reservationNumber:'',
    userEmail:''
  },
  tempDetails: {
    passengerPhoneNumber: null
  },
  alertDetails: {
    formAlertDetails: {
      visible: false,
      header: '',
      content: '',
      type: '',
      autoDismiss: false,
      autoDismissInterval: 0
    },
    loginAlertDetails: {
      visible: false,
      header: '',
      content: '',
      type: '',
      autoDismiss: false,
      autoDismissInterval: 0
    },
    signUpAlertDetails: {
      visible: false,
      header: '',
      content: '',
      type: '',
      autoDismiss: false,
      autoDismissInterval: 0
    }
  }
};

export default initialState;
