import moment from "moment";

function getTimeParts(time) {
  let timeArray = time.split(' ');
  const hourPart = timeArray[0];
  const amPmPart = timeArray[1];
  const hourPartArray = hourPart.split(':');
  let hours = Number(hourPartArray[0]);
  const minutes = Number(hourPartArray[1]);
  if (amPmPart === 'pm') {
    hours += 12;
  }
  return { hours, minutes };
}

export function combineDateAndTime(date, time) {
  const timeParts = getTimeParts(time);
  const hours = timeParts.hours;
  const minutes = timeParts.minutes;
  const datePart = `${ date.getFullYear() }-${ appendLeadingZeroes(date.getMonth() + 1) }-${ appendLeadingZeroes(date.getDate()) }`;
  return `${ datePart } ${ appendLeadingZeroes(hours) }:${ appendLeadingZeroes(minutes) }`;
}

export function formatDate(date, separator=null) {
  let newDate = date.toISOString().slice(0, 19);
  if (separator) {
    newDate = newDate.replace(/-/g, separator);
  }
  return newDate.replace("T", " ");
}

export function formatTime(date) {
  const amPm = date.getHours() > 11 ? 'pm' : 'am';
  let hours = date.getHours();
  if (hours > 12) {
    hours -= 12;
  }
  const minutes = date.getMinutes();
  return `${appendLeadingZeroes(hours)}:${appendLeadingZeroes(minutes)} ${amPm}`;
}

export function combineAndAdd(date, time, seconds) {
  const timeParts = getTimeParts(time);
  const hours = timeParts.hours;
  const minutes = timeParts.minutes;
  const dateMoment = moment(date).set('hour', hours).set('minute', minutes).set('second', 0).add(seconds, 'seconds');
  return dateMoment.toDate();
}

export function normalDateToBookingConfirmedDate(pickUpDate) {
  if(pickUpDate) {
    const weekDay = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const yearMonth = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    return weekDay[pickUpDate.getDay()]+', '+yearMonth[pickUpDate.getMonth()]+' '+pickUpDate.getDate()+', '+pickUpDate.getFullYear();
  }
  return '';
}

function appendLeadingZeroes(n) {
  if(n <= 9) {
    return "0" + n;
  }
  return n;
}

/**
 * returns a specific time format. ex: February 16, 2021 10:24 AM
 * @param indicatedTime
 * @return {string}
 */
export function getAirportDateAndTimeFormat(indicatedTime = null) {
  const date = indicatedTime ? moment(indicatedTime) : moment();
  const time = date.format("hh:mm A");
  const yearMonth = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  return yearMonth[date.month()] + ' ' +date.date() + ', ' + date.year() + ' ' + time;
}

/**
 * returns the day of the current month
 * @param queryDate
 * @return {number}
 */
export function getDayOfMonth(queryDate){
  return moment(queryDate).date();
}

/**
 * returns a specific time format.ex: Monday, February 15
 * @param flightDate
 * @return {string}
 */
export function getFlightDateFormatted(flightDate) {
  const date = moment(flightDate, "YYYY-MM-DD");

  if (flightDate) {
    const weekDay = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const yearMonth = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return weekDay[date.day()] + ', ' +yearMonth[date.month()] + ' ' + date.date();
  }
}

/**
 * returns the month of the current year
 * @param queryDate
 * @return {number}
 */
export function getMonthOfYear(queryDate){
  return moment(queryDate).month() + 1;
}

/**
 * returns the current year
 * @param queryDate
 * @return {number}
 */
export function getYear(queryDate){
  return moment(queryDate).year();
}
