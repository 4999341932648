import { AbstractDataService } from "./AbstractDataService";
import { combineDateAndTime } from '../utils/dateUtil';
import { getToken } from "../utils/cookieUtil";
import {getDeviceInfo} from "../utils/deviceUtils";
import {isConcierge} from "../utils/widgetUtils";

export class KaptynDataService extends AbstractDataService {

  constructor() {
    super();
  }

  get apiRoot() {
    return `${ process.env.KAPTYN_API_BASE_URL }/v1.1`;
  }

  signUp(payload){
    return this.post('/signUp?web=1', payload);
  }

  login(email, password) {
    const data = {
      email,
      password,
      device: getDeviceInfo({
        isConcierge: isConcierge()
      })
    };

    return this.put('/login?web=1', data);
  }

  logout() {
    const headers = {
      'Authorization': `Bearer ${ getToken() }`
    };

    return this.put('/logout?web=1',null, headers);
  }

  getServiceLevels(fromAddress, fromCoordinates, toAddress, toCoordinates, numberOfPassengers, pickUpDate, pickUpTime, duration, flight) {
    return this.get('/service_levels', { web: 1, startLoc: fromCoordinates }).then((serviceLevels) => {
      if (!duration && !flight.airportChecked) { // null
        const promises = serviceLevels.map((serviceLevel) => {
          return this.getQuote(fromAddress, fromCoordinates, toAddress, toCoordinates, numberOfPassengers, pickUpDate, pickUpTime, duration, serviceLevel._id, serviceLevel.group).then((quote) => {
            serviceLevel.rate = quote.total ? quote.total / 100 : null;
            serviceLevel.duration = quote.duration;
            return serviceLevel;
          });
        });
        return Promise.all(promises);
      } else if (duration) { //string
        const promises = serviceLevels.filter(function (serviceLevel) {
          return serviceLevel.pricing.hourlyQuote === true;
        }).map((serviceLevel) => {
          return this.getQuote(fromAddress, fromCoordinates, toAddress, toCoordinates, numberOfPassengers, pickUpDate, pickUpTime, duration, serviceLevel._id, serviceLevel.group).then((quote) => {
            serviceLevel.rate = quote.total ? quote.total / 100 : null;
            serviceLevel.duration = quote.duration;
            return serviceLevel;
          });
        })
        return Promise.all(promises);
      } else if (flight.airportChecked) {
        const promises = serviceLevels.filter(function (serviceLevel) {
          return serviceLevel.inputFields.filter(function(inputField) {
             return inputField.inputType === 5;
          }).length > 0
        }).map((serviceLevel) => {
          return this.getQuote(fromAddress, fromCoordinates, toAddress, toCoordinates, numberOfPassengers, pickUpDate, pickUpTime, duration, serviceLevel._id, serviceLevel.group).then((quote) => {
            serviceLevel.rate = quote.total ? quote.total / 100 : null;
            serviceLevel.duration = quote.duration;
            return serviceLevel;
          });
        })
        return Promise.all(promises);
      } else if (duration && flight.airportChecked) {
        const promises = serviceLevels.filter(function (serviceLevel) {
          return serviceLevel.inputFields.filter(function(inputField) {
            return inputField.inputType === 5;
          }).length > 0 && serviceLevel.pricing.hourlyQuote === true;
        }).map((serviceLevel) => {
          return this.getQuote(fromAddress, fromCoordinates, toAddress, toCoordinates, numberOfPassengers, pickUpDate, pickUpTime, duration, serviceLevel._id, serviceLevel.group).then((quote) => {
            serviceLevel.rate = quote.total ? quote.total / 100 : null;
            serviceLevel.duration = quote.duration;
            return serviceLevel;
          });
        })
        return Promise.all(promises);
      }
    });
  }

  getQuote(fromAddress, fromCoordinates, toAddress, toCoordinates, numberOfPassengers, pickUpDate, pickUpTime, duration, serviceLevel, group) {
    let startTime = combineDateAndTime(pickUpDate, pickUpTime);

    const params = {
      web: 1,
      from: fromAddress,
      startLoc: fromCoordinates,
      to: toAddress,
      destLoc: toCoordinates,
      passengers: numberOfPassengers,
      startTime,
      serviceLevel,
      group
    };


    if (duration) {
      params.estimatedTime = parseInt(duration) * 3600;
    }

    return this.get('/ride/quote', params);
  }

  getPaymentCards(userId){
    const token = getToken();
    const headers = {};
    let guestPrefix = 'guest/';

    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
      guestPrefix = '';
    }

    const endpoint = userId ? `${ guestPrefix }billing/user/${ userId }/cards` : 'billing/user/cards';

    return this.get(endpoint,null, headers);
  }

  createPaymentCard(userId, cardToken) {
    const token = getToken();
    const headers = {};
    let guestPrefix = 'guest/';

    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
      guestPrefix = '';
    }

    const endpoint = userId ? `${ guestPrefix }billing/user/${ userId }/cards` : 'billing/user/cards';
    return this.post(endpoint, { token: cardToken }, headers);
  }

  deletePaymentCard(userId, cardId) {
    const token = getToken();
    const headers = {};
    let guestPrefix = 'guest/';

    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
      guestPrefix = '';
    }

    const endpoint = userId ? `${ guestPrefix }billing/user/${ userId }` : 'billing/user';
    return this.del(`${ endpoint }/cards/${ cardId }`, null, headers);
  }

  setPrimaryCard(userId, cardId) {
    const token = getToken();
    const headers = {};
    let guestPrefix = 'guest/';

    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
      guestPrefix = '';
    }
    const endpoint = userId ? `${ guestPrefix }billing/user/${ userId }` : `billing/user`;
    return this.put(`${ endpoint }/cards/${ cardId }/primary`, null, headers);
  }

  createUser(firstName, lastName, email, phone) {
    const token = getToken();
    const headers = {};
    let guestPrefix = 'guest/';

    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
      guestPrefix = '';
    }

    const params = {
      web: 1,
      firstName,
      lastName,
      email,
      phone
    };

    return this.post(`${ guestPrefix }user`, params, headers);
  }

  createRide(group, startTime, riders, passengers, serviceLevel, estimatedTime, extras, extrasParsed) {
    const token = getToken();
    const headers = {};
    let guestPrefix = 'guest/';

    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
      guestPrefix = '';
    }

    const params = {
      web: 1,
      group,
      type: 'standard',
      startTime,
      rider: riders[0].rider,
      riders,
      passengers,
      serviceLevel,
      paymentMethod: 0, // Credit
      extras: extras,
      extrasParsed
    };

    if (estimatedTime) {
      params.estimatedTime = estimatedTime;
    }

    return this.post(`${guestPrefix}ride/create`, params, headers);
  }

  sendEmail(rideId, bcc = null, bookingConfirmed = false, pickup_time, dropoff_time) {
    const token = getToken();
    const headers = {};
    let guestPrefix = 'guest/';

    if (token) {
      headers['Authorization'] = `Bearer ${ token }`;
      guestPrefix = '';
    }

    const data = {
      rideId,
      bcc,
      bookingConfirmed,
      pickup_time,
      dropoff_time
    };
    return this.post(`${ guestPrefix }billing/rides/` + rideId + '/receipt', data, headers);
  }

  changePassword(tempPassword, newPassword, passwordConfirm) {
    const data = {
      temp_password: tempPassword,
      new_password: newPassword,
      confirm_new_password: passwordConfirm,
    };

    return this.put('/user/password/reset?web=1', data, { 'dashride-api-key': process.env.DEFAULT_API_KEY });
  }

  getAirlines() {
    return this.get('/flight-stats/airlines', null);
  }


  getFlightStatus(flight){
    return this.get('/flight-stats/flight', flight);
  }
}
