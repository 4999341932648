import * as actionTypes from '../constants/ActionTypes';
import initialState from './InitialState';

export default function widget(state = initialState, action) {

  // Handle errors first
  if (action.error) {
    return state.set('error', action.payload);
  }

  switch (action.type) {
    case actionTypes.SET_API_KEY:
      return {
        ...state,
        apiKey: action.payload.apiKey
      };

    case actionTypes.SET_IS_CONCIERGE:
      return {
        ...state,
        isConcierge: action.payload.isConcierge
      };

    case actionTypes.SET_CONCIERGE_USER_ID:
      return {
        ...state,
        conciergeUserId: action.payload.conciergeUserId
      };

    case actionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep
      };

    case actionTypes.SET_PICK_UP_DATE:
      // eslint-disable-next-line no-case-declarations
      const setPickUpDateRideDetails = {
        ...state.rideDetails,
        pickUpDate: action.payload.pickUpDate
      };

      return {
        ...state,
        rideDetails: setPickUpDateRideDetails
      };

    case actionTypes.SET_PICK_UP_TIME:
      // eslint-disable-next-line no-case-declarations
      const setPickUpTimeRideDetails = {
        ...state.rideDetails,
        pickUpTime: action.payload.pickUpTime
      };

      return {
        ...state,
        rideDetails: setPickUpTimeRideDetails
      };

    case actionTypes.SET_PICK_UP_LOCATION:
      // eslint-disable-next-line no-case-declarations
      const setPickUpLocationRideDetails = {
        ...state.rideDetails,
        fromCoordinates: action.payload.fromCoordinates,
        fromAddress: action.payload.fromAddress
      };
      return {
        ...state,
        rideDetails: setPickUpLocationRideDetails
      };

    case actionTypes.SET_DROP_OFF_LOCATION:
      // eslint-disable-next-line no-case-declarations
      const setDropOffLocationRideDetails = {
        ...state.rideDetails,
        toAddress: action.payload.toAddress,
        toCoordinates: action.payload.toCoordinates
      };
      return {
        ...state,
        rideDetails: setDropOffLocationRideDetails
      };

    case actionTypes.SET_ESTIMATED_DURATION:
      // eslint-disable-next-line no-case-declarations
      const setEstimatedDurationRideDetails = {
        ...state.rideDetails,
        estimatedDuration: action.payload.estimatedDuration
      };

      return {
        ...state,
        rideDetails: setEstimatedDurationRideDetails
      };

    case actionTypes.SET_RIDE_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newRideDetails = {
        ...state.rideDetails,
        rideType: action.payload.rideType,
        numberOfPassengers: action.payload.numberOfPassengers,
        numberOfBags: action.payload.numberOfBags,
        fromAddress: action.payload.fromAddress,
        fromCoordinates: action.payload.fromCoordinates,
        toAddress: action.payload.toAddress,
        toCoordinates: action.payload.toCoordinates,
        pickUpDate: action.payload.pickUpDate,
        pickUpTime: action.payload.pickUpTime,
        duration: action.payload.duration,
        flight: {
          number: action.payload.flight.number,
          airline: action.payload.flight.airline,
          carrier: action.payload.flight.carrier,
          airportChecked: action.payload.flight.airportChecked
        },
      };
      return {
        ...state,
        rideDetails: newRideDetails
      };

    case actionTypes.SET_VEHICLE_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newVehicleDetails = {
        ...state.vehicleDetails,
        serviceLevels: action.payload.serviceLevels,
        serviceLevel: action.payload.serviceLevel,
        group: action.payload.group,
        rate: action.payload.rate,
        name: action.payload.name,
        description: action.payload.description
      };
      return {
        ...state,
        vehicleDetails: newVehicleDetails
      };

    case actionTypes.GET_SERVICE_LEVELS:
      return state;

    case actionTypes.SET_PASSENGER_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newPassengerDetails = {
        ...state.passengerDetails,
        company:action.payload.company,
        name: action.payload.name,
        lastname: action.payload.lastname,
        email: action.payload.email,
        phone: action.payload.phone
      };
      return {
        ...state,
        passengerDetails: newPassengerDetails
      };

    case actionTypes.SET_PAYMENT_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newPaymentDetails = {
        ...state.paymentDetails,
        cardHolder: action.payload.cardHolder,
        number: action.payload.number,
        expMonth: action.payload.expMonth,
        expYear: action.payload.expYear,
        cvv: action.payload.cvv,
        paymentCards: action.payload.paymentCards,
        loadingPaymentCards: action.payload.loadingPaymentCards
      };
      return {
        ...state,
        paymentDetails: newPaymentDetails
      };

    case actionTypes.GET_PAYMENT_CARDS:
      return state;

    case actionTypes.SET_PASSENGER_PHONE_NUMBER:
      // eslint-disable-next-line no-case-declarations
      const setPassengerPhoneNumber = {
        ...state.tempDetails,
        passengerPhoneNumber: action.payload.phone
      };

      return {
        ...state,
        tempDetails: setPassengerPhoneNumber
      };

    case actionTypes.SET_MODAL_STATE:
      return {
        ...state,
        modalState: action.payload
      };

    case actionTypes.SET_MODAL_STATE_BOOKING_CONFIRMED:
      return {
        ...state,
        modalStateBookingConfirmed: action.payload
      };

    case actionTypes.SET_BOOKING_CONFIRMED_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newBookingConfirmedDetails = {
        ...state.bookingConfirmedDetails,
        scheduledDate: action.payload.scheduledDate,
        reservationNumber: action.payload.reservationNumber,
        userEmail: action.payload.userEmail
      };
      return {
        ...state,
        bookingConfirmedDetails: newBookingConfirmedDetails
      };

    case actionTypes.SET_FORM_LOADING:
      return {
        ...state,
        isFormLoading: action.payload.isFormLoading
      };

    case actionTypes.SET_SESSION_LOADING:
      return {
        ...state,
        isSessionLoading: action.payload.isSessionLoading
      };

    case actionTypes.SET_INITIAL_STATE:
      // eslint-disable-next-line no-case-declarations
      const rideDetails = {
        ...initialState.rideDetails,
        pickUpDate: state.rideDetails.pickUpDate,
        pickUpTime: state.rideDetails.pickUpTime
      };
      return {
        ...initialState,
        apiKey: state.apiKey,
        isConcierge: state.isConcierge,
        rideDetails: rideDetails
      };

    case actionTypes.SET_FORM_ALERT_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newFormAlertDetails = {
        ...state.alertDetails,
        formAlertDetails: action.payload.alert
      };
      return {
        ...state,
        alertDetails: newFormAlertDetails
      };

    case actionTypes.SET_LOGIN_ALERT_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newLoginAlertDetails = {
        ...state.alertDetails,
        loginAlertDetails: action.payload.alert
      };
      return {
        ...state,
        alertDetails: newLoginAlertDetails
      };

    case actionTypes.SET_SIGN_UP_ALERT_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const newSignUpAlertDetails = {
        ...state.alertDetails,
        signUpAlertDetails: action.payload.alert
      };
      return {
        ...state,
        alertDetails: newSignUpAlertDetails
      };

    case actionTypes.SET_CONTINUE_AS_GUEST:
      return {
        ...state,
        continueAsGuest: action.payload.continueAsGuest
      };

    case actionTypes.SET_GUEST_USER_ID:
      return {
        ...state,
        guestUserId: action.payload.guestUserId
      };

    default:
      return state;
  }
}
