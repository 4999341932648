import * as actionTypes from '../constants/ActionTypes';
import { KaptynDataService } from "../dataservices/KaptynDataService";

export function setApiKey(apiKey) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_API_KEY, payload: apiKey });
  }
}

export function setIsConcierge(isConcierge) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_IS_CONCIERGE, payload: isConcierge });
  }
}

export function setConciergeUserId(conciergeUserId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CONCIERGE_USER_ID, payload: conciergeUserId });
  }
}

export function setCurrentStep(currentStep) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CURRENT_STEP, payload: currentStep });
  };
}

export function setPickUpLocation(pickUpLoation) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_PICK_UP_LOCATION, payload: pickUpLoation });
  };
}

export function setDropOffLocation(dropOffLocation) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_DROP_OFF_LOCATION, payload: dropOffLocation });
  };
}

export function setPickUpDate(pickUpDate) {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_PICK_UP_DATE, payload:pickUpDate});
  }
}

export function setPickUpTime(pickUpTime) {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_PICK_UP_TIME, payload:pickUpTime});
  }
}

export function setEstimatedDuration(estimatedDuration) {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_ESTIMATED_DURATION, payload:estimatedDuration});
  }
}

export function setRideDetails(rideDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_RIDE_DETAILS, payload: rideDetails });
  };
}

export function setVehicleDetails(vehicleDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_VEHICLE_DETAILS, payload: vehicleDetails });
  };
}

export function getServiceLevels() {
  return (dispatch, getState) => {
    const vehicleDetails = getState().widget.vehicleDetails;
    const rideDetails = getState().widget.rideDetails;
    const dataService = new KaptynDataService();
    return dataService.getServiceLevels(rideDetails.fromAddress,
      rideDetails.fromCoordinates,
      rideDetails.toAddress,
      rideDetails.toCoordinates,
      rideDetails.numberOfPassengers,
      rideDetails.pickUpDate,
      rideDetails.pickUpTime,
      rideDetails.duration,
      rideDetails.flight).then((results) => {
        let newVehicleDetails = {
          ...vehicleDetails,
          serviceLevels: results
        };
        dispatch({ type: actionTypes.SET_VEHICLE_DETAILS, payload: newVehicleDetails });
      }
    ).catch((error) => {
      const alert = {
        visible: true,
        header: 'Error',
        content: error.message,
        type: 'error',
        autoDismiss: true,
        autoDismissInterval: 5000
      };
      dispatch({ type: actionTypes.SET_FORM_ALERT_DETAILS, payload: { alert } });
    });
  };
}

export function setPassengerDetails(passengerDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_PASSENGER_DETAILS, payload: passengerDetails });
  };
}

export function setPaymentDetails(paymentDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_PAYMENT_DETAILS, payload: paymentDetails });
  };
}

export function getPaymentCards(userId) {
  return (dispatch, getState) => {
    const paymentDetails = getState().widget.paymentDetails;
    const dataService = new KaptynDataService();
    return dataService.getPaymentCards(userId).
    then((results) => {
        let newPaymentDetails = {
          ...paymentDetails,
          paymentCards: results,
          loadingPaymentCards: false
        };
        dispatch({ type: actionTypes.SET_PAYMENT_DETAILS, payload: newPaymentDetails });
      }
    ).catch((error) => {
      const alert = {
        visible: true,
        header: 'Error',
        content: error.message,
        type: 'error',
        autoDismiss: true,
        autoDismissInterval: 5000
      };
      dispatch({ type: actionTypes.SET_FORM_ALERT_DETAILS, payload: { alert } });
    });
  };
}

export function setPassengerPhoneNumber(passengerPhoneNumber) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_PASSENGER_PHONE_NUMBER, payload: passengerPhoneNumber });
  }
}

export function setModalState(modalState) {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_MODAL_STATE, payload: modalState});
  }
}

export function setModalStateBookingConfirmed(modalStateBookingConfirmed) {
  return (dispatch) => {
    dispatch({type: actionTypes.SET_MODAL_STATE_BOOKING_CONFIRMED, payload: modalStateBookingConfirmed});
  }
}

export function setBookingConfirmedDetails(bookingConfirmedDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_BOOKING_CONFIRMED_DETAILS, payload: bookingConfirmedDetails });
  };
}

export function setFormLoading(loadingData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_FORM_LOADING, payload: loadingData });
  }
}

export function setSessionLoading(loadingData) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_SESSION_LOADING, payload: loadingData });
  }
}

export function setInitialState() {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_INITIAL_STATE });
  };
}

export function setFormAlertDetails(formAlertDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_FORM_ALERT_DETAILS, payload: formAlertDetails });
  };
}

export function setLoginAlertDetails(loginAlertDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_LOGIN_ALERT_DETAILS, payload: loginAlertDetails });
  };
}

export function setSignUpAlertDetails(signUpAlertDetails) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_SIGN_UP_ALERT_DETAILS, payload: signUpAlertDetails });
  };
}

export function setContinueAsGuest(continueAsGuest) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_CONTINUE_AS_GUEST, payload: continueAsGuest });
  }
}

export function setGuestUserId(guestUserId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_GUEST_USER_ID, payload: guestUserId });
  }
}


