import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getCurrencyFormattedNumber } from '../../utils/numberFormatUtil';
import { Card, Grid, Image, Loader } from 'semantic-ui-react';
import { getEmail, getFirstName, getLastName, getPhoneNumber, hasActiveSession } from '../../utils/cookieUtil';
import { PASSENGER_DETAILS, PAYMENT_DETAILS } from '../../constants/WidgetSteps';
import BMW750Image from "../../images/cars/bmw-750.png";
import DefaultImage from "../../images/cars/default-car.png";
import LuxuryLargeSUVImage from "../../images/cars/luxury-large-suv.png";
import Model3Image from "../../images/cars/model-3.png";
import ModelSImage from "../../images/cars/model-s.png";
import ModelXImage from "../../images/cars/model-x.png";
import SprinterVanImage from "../../images/cars/sprinter-van.png";
import StretchLimoImage from "../../images/cars/stretch-limo.png";
import SUVImage from "../../images/cars/suv.png";
import * as gtmConstants from '../../constants/GtmConstants';
import {push} from '../../utils/gtmUtil';

const MODEL_3 = 'model 3';
const MODEL_S = 'model s';
const MODEL_X = 'model x';
const BMW_750 = 'bmw 750';
const LUXURY_LARGE_SUV = 'luxury large suv';
const SUV = 'xl';
const STRETCH_LIMO = 'stretch';
const SPRINTER_VAN = 'sprinter';

class VehicleDetailsForm extends React.Component {

  constructor(props) {
    super(props);
    this.props.setVehicleDetails({
      serviceLevels: [],
      serviceLevel: null,
      rate: null,
      name: null,
      description: null
    });
    this.onVehicleSelected = this.onVehicleSelected.bind(this);
  }

  componentDidMount() {
    this.props.getServiceLevels({});
    push(gtmConstants.GTM_PAGE_VEHICLE_DETAILS, gtmConstants.GTM_PAGE_TITLE_VEHICLE_DETAILS);
  }

  onVehicleSelected(e) {
    const serviceLevelId = _.get(e.target, 'id').replace('btn_', '');
    const filteredServiceLevels = this.props.vehicleDetails.serviceLevels.filter((serviceLevel) => {
      return serviceLevel._id === serviceLevelId;
    });
    if (filteredServiceLevels.length === 1) {
      const serviceLevel = filteredServiceLevels[0];
      let newVehicleDetails = {
        ...this.props.vehicleDetails,
        serviceLevel: serviceLevel._id,
        group: serviceLevel.group,
        rate: serviceLevel.rate,
        name: serviceLevel.name,
        description: serviceLevel.description
      };
      let duration = null;
      if (serviceLevel.duration && serviceLevel.duration > 0) {
        duration = serviceLevel.duration;
      }
      if (duration) {
        this.props.setEstimatedDuration({ estimatedDuration: duration });
      }
      this.props.setVehicleDetails(newVehicleDetails);

      // If there is no active session and this is not the concierge scenario and "continue as guest" has not been selected,
      // it means the person using the widget has to pass through the modal before they can continue.
      if (!hasActiveSession() && !this.props.isConcierge && !this.props.continueAsGuest) {
        this.props.setModalState(true);
      }
        // Move on. If this is the concierge scenario or ths user selected to continue as guest, we need to get the passenger
      // details. Otherwise we assume the passenger is the person using the widget.
      else {
        // Populate the passenger details with the details of the current user in session.
        if (hasActiveSession() && !this.props.isConcierge) {
          this.props.setPassengerDetails({
            name: getFirstName() || '',
            lastname: getLastName() || '',
            email: getEmail() || '',
            phone: getPhoneNumber() || ''
          });
        }
        this.props.setCurrentStep({ currentStep: this.props.isConcierge || this.props.continueAsGuest ? PASSENGER_DETAILS : PAYMENT_DETAILS });
      }
    } else {
      const alert = {
        visible: true,
        header: 'Error',
        content: 'Unexpected filtered service levels length after vehicle selected',
        type: 'error',
        autoDismiss: true,
        autoDismissInterval: 5000
      };
      this.props.setAlertDetails({ alert });
    }
  }

  _renderCarImage(name, description) {
    let image = DefaultImage;
    name = name ? name.toLowerCase() : '';
    description = description ? description.toLowerCase() : '';
    if (name.indexOf(MODEL_3) > -1 || description.indexOf(MODEL_3) > -1) {
      image = Model3Image;
    } else if (name.indexOf(MODEL_S) > -1 || description.indexOf(MODEL_S) > -1) {
      image = ModelSImage
    } else if (name.indexOf(MODEL_X) > -1 || description.indexOf(MODEL_X) > -1) {
      image = ModelXImage;
    } else if (name.indexOf(BMW_750) > -1 || description.indexOf(BMW_750) > -1) {
      image = BMW750Image;
    } else if (name.indexOf(LUXURY_LARGE_SUV) > -1 || description.indexOf(LUXURY_LARGE_SUV) > -1) {
      image = LuxuryLargeSUVImage;
    } else if (name.indexOf(SUV) > -1 || description.indexOf(SUV) > -1) {
      image = SUVImage;
    } else if (name.indexOf(STRETCH_LIMO) > -1 || description.indexOf(STRETCH_LIMO) > -1) {
      image = StretchLimoImage;
    } else if (name.indexOf(SPRINTER_VAN) > -1 || description.indexOf(SPRINTER_VAN) > -1) {
      image = SprinterVanImage;
    }
    return <Image src={ image } size='small'/>
  }

  render() {
    let content;
    let cards = [];
    if (this.props.vehicleDetails.serviceLevels && this.props.vehicleDetails.serviceLevels.length > 0) {
      this.props.vehicleDetails.serviceLevels.forEach((serviceLevel) => {
        let card = <Card key={ serviceLevel.id } className="ui centered card p-2 serviceLevel" >
          <meta meta name="viewport" content=
            "width=device-width, user-scalable=no" />
          <Grid>
            <Grid.Column width={6}>
              { this._renderCarImage(serviceLevel.name, serviceLevel.description) }
            </Grid.Column>
            <Grid.Column width={9}>
              <p className="h3 m-1">{ serviceLevel.rate ? getCurrencyFormattedNumber(serviceLevel.rate) : 'Rate not available' }</p>
              <Card.Meta className="text-secondary"><p className="m-2 text-md-left">{ serviceLevel.name }</p></Card.Meta>
              <Card.Description><p className="m-2 text-md-left">{ serviceLevel.description }</p></Card.Description>
              <button id={ 'btn_' + serviceLevel.id } className="ui blue button strongblue m-2 selectVehicleBtn" tabIndex="0" onClick={ this.onVehicleSelected }>Select Vehicle</button>
            </Grid.Column>
          </Grid>
        </Card>;
        cards.push(card);
      });
      content = cards;
    } else {
      content = <Loader active inline='centered' />;
    }
    return (
      <div className="p-2">
        <h1 className="ui header formHeader">Select Vehicle</h1>
        <div className="col-sm-12 col-md-12 p-3">
          { content }
        </div>
      </div>
    );
  }
}

VehicleDetailsForm.propTypes = {
  isConcierge: PropTypes.bool.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setEstimatedDuration: PropTypes.func.isRequired,
  vehicleDetails: PropTypes.object.isRequired,
  getServiceLevels: PropTypes.func.isRequired,
  setVehicleDetails: PropTypes.func.isRequired,
  setPassengerDetails: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  continueAsGuest: PropTypes.bool.isRequired,
  setAlertDetails: PropTypes.func.isRequired
};

export default VehicleDetailsForm;
