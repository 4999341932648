export const SET_API_KEY = 'SET_API_KEY';
export const SET_IS_CONCIERGE = 'SET_IS_CONCIERGE';
export const SET_CONCIERGE_USER_ID = 'SET_CONCIERGE_USER_ID';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_PICK_UP_LOCATION = 'SET_PICK_UP_LOCATION';
export const SET_DROP_OFF_LOCATION = 'SET_DROP_OFF_LOCATION';
export const SET_PICK_UP_DATE = 'SET_PICK_UP_DATE';
export const SET_PICK_UP_TIME = 'SET_PICK_UP_TIME';
export const SET_ESTIMATED_DURATION = 'SET_ESTIMATED_DURATION';
export const SET_RIDE_DETAILS = 'SET_RIDE_DETAILS';
export const SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS';
export const GET_SERVICE_LEVELS = 'GET_SERVICE_LEVELS';
export const SET_PASSENGER_DETAILS = 'SET_PASSENGER_DETAILS';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const GET_PAYMENT_CARDS = 'GET_PAYMENT_CARDS';
export const SET_PASSENGER_PHONE_NUMBER = 'SET_PASSENGER_PHONE_NUMBER';
export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const SET_MODAL_STATE_BOOKING_CONFIRMED = 'SET_MODAL_STATE_BOOKING_CONFIRMED';
export const SET_BOOKING_CONFIRMED_DETAILS = 'SET_BOOKING_CONFIRMED_DETAILS';
export const SET_FORM_LOADING = 'SET_FORM_LOADING';
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_FORM_ALERT_DETAILS = 'SET_FORM_ALERT_DETAILS';
export const SET_LOGIN_ALERT_DETAILS = 'SET_LOGIN_ALERT_DETAILS';
export const SET_SIGN_UP_ALERT_DETAILS = 'SET_SIGN_UP_ALERT_DETAILS';
export const SET_GUEST_USER_ID = 'SET_GUEST_USER_ID';
export const SET_CONTINUE_AS_GUEST = 'SET_CONTINUE_AS_GUEST';
