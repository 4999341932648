import React from 'react';
import { Dropdown } from 'semantic-ui-react'
import { KaptynDataService } from "../dataservices/KaptynDataService";
import {
  getAirportDateAndTimeFormat,
  getDayOfMonth,
  getFlightDateFormatted,
  getMonthOfYear,
  getYear
} from "../utils/dateUtil";
import {firstLetterToUpperCase} from "../utils/stringUtil";
import {
  FLIGHT_STATUS_CANCELED,
  FLIGHT_STATUS_DELAYED, FLIGHT_STATUS_ERROR, FLIGHT_STATUS_LANDED, FLIGHT_STATUS_NOT_FOUND, FLIGHT_STATUS_REROUTED,
  FLIGHT_STATUS_SCHEDULED,
  PRIMARY_GREEN, PRIMARY_ORANGE,
  PRIMARY_RED, PRIMARY_WHITE
} from "../constants/AirportConstants";
import PropTypes from "prop-types";

const stringUtils = require("underscore.string");

class Airport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.rideDetails,
      flight: {
        airline: '',
        number: props.flightNumber ? props.flightNumber : '',
        carrier: props.carrier ? props.carrier : '',
        day: '',
        direction: 'arr',
        month: null,
        year: null,
        flightDate:'',
        departureAirport:'',
        arrivalAirport:'',
        status: null,
        statusColor: "#ffffff",
        departureTime: '',
        departureDelay:'',
        arrivalTime:'',
        arrivalDelay:'',
        show: false,
      },
      updatedTime:'',
      checked: props.airportChecked,
      airlinesList: [{key: 'loading', text:'Loading...', value: ''}],
      rideDate: props.rideDate ? props.rideDate : ''
    }
    this.dataService = new KaptynDataService();
    this._handleAirportChange = this._handleAirportChange.bind(this);
    this._handleAirlineChange = this._handleAirlineChange.bind(this);
    this._handleFlightNumberChange = this._handleFlightNumberChange.bind(this);
    this._handleOnClickSearchFlight = this._handleOnClickSearchFlight.bind(this);
    this._renderFlightFound = this._renderFlightFound.bind(this);
    this._renderFlightNotFound = this._renderFlightNotFound.bind(this);
  }


  componentDidMount(){
    this._handleAirportChange();
  }

  _handleAirportChange() {
    let formattedAirlinesList =  [];
    this.setState({
      checked: !this.state.checked
    }, () => {
      if (this.state.checked) {
        this.props.setFormLoading({ isFormLoading: true });
        this.dataService.getAirlines().then((result) => {
          const airlinesList = result.sort((a, b) => a.name.localeCompare(b.name));
          airlinesList.map((result) => {
            formattedAirlinesList.push({
              key: result.fs ? result.fs : undefined,
              text: result.name ? result.name : undefined,
              value: result.iata ? result.iata : result.icao ? result.icao : undefined
            });
          });
          this.setState({
            ...this.state.rideDetails,
            airlinesList: formattedAirlinesList
          });
          this.props.setFormLoading({ isFormLoading: false });
          // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
          this.props.setFormLoading({ isFormLoading: false });
          const alert = {
            visible: true,
            header: 'Error',
            autoDismissInterval: 5000
          };
          this.props.setAlertDetails({ alert });
        });
      }
      this.props.setRideDetails({
        ...this.props.rideDetails,
        flight: {
          ...this.state.flight,
          airportChecked: this.state.checked
        }
      })
    })
  }

  _handleAirlineChange(e, value) {
    let newState
    if (value) {
      const airline = e.target && e.target.textContent  ? e.target.textContent : undefined;
      const carrier = value && value.value ? value.value : undefined;
      const airportChecked = this.state.checked;
      newState = {
        ...this.props.rideDetails,
        flight: {
          ...this.state.flight,
          airline,
          carrier,
          airportChecked
        }
      }
      this.props.setRideDetails(newState);
      return this.setState(newState);
    }
    newState = {
      ...this.props.rideDetails,
      flight: {
        ...this.state.flight,
        airline:'',
        carrier:''
      }
    }
    this.props.setRideDetails(newState);
    return this.setState(newState);
  }

  _handleFlightNumberChange(e) {
    const { value } = e.target;
    let newState;
    if (value) {
      newState = {
        ...this.props.rideDetails,
        flight: {
          ...this.state.flight,
          number: value
        }
      };
      this.props.setRideDetails(newState);
      return this.setState(newState);
    }
    newState = {
      ...this.props.rideDetails,
      flight: {
        ...this.state.flight,
        number:''
      }
    };
    this.props.setRideDetails(newState);
    return this.setState(newState);
  }


  _handleOnClickSearchFlight() {
    if (this.state.flight.carrier !== '' && this.state.flight.number !== '' && this.state.flight.airline) {
      const flight = {
        carrier: this.state.flight.carrier,
        day: getDayOfMonth(this.props.rideDate),
        direction: this.state.flight.direction,
        flight: this.state.flight.number,
        month: getMonthOfYear(this.props.rideDate),
        year: getYear(this.props.rideDate)
      };

      this.props.setFormLoading({ isFormLoading: true });
      this.dataService.getFlightStatus(flight).then((response) => {
        this.setState({
          flight: {
            ...this.state.flight,
            flightDate: getFlightDateFormatted(this.props.rideDate),
            departureAirport: response.departureAirport ? response.departureAirport : undefined,
            arrivalAirport: response.arrivalAirport ? response.arrivalAirport : undefined,
            status: response.status ? firstLetterToUpperCase(response.status) : undefined,
            statusColor: (response.status) ? (response.status === FLIGHT_STATUS_CANCELED ? PRIMARY_RED : response.status === FLIGHT_STATUS_SCHEDULED ? PRIMARY_GREEN : response.status === FLIGHT_STATUS_DELAYED ? PRIMARY_ORANGE : response.status === FLIGHT_STATUS_REROUTED ? PRIMARY_RED : response.status === FLIGHT_STATUS_ERROR ? PRIMARY_RED : response.status === FLIGHT_STATUS_LANDED ? PRIMARY_GREEN : PRIMARY_WHITE) : PRIMARY_WHITE,
            departureTime: response.departureTime ? getAirportDateAndTimeFormat(response.departureTime) : undefined,
            departureDelay: response.departureDelay ? response.departureDelay : undefined,
            arrivalTime: response.arrivalTime ? getAirportDateAndTimeFormat(response.arrivalTime) : undefined,
            arrivalDelay: response.arrivalDelay ? response.arrivalDelay : undefined,
            show: true
          },
          updatedTime: getAirportDateAndTimeFormat()
        });
        this.props.setFormLoading({ isFormLoading: false });
        if(response.status != "error"){
          this.props.flightFound();
        }
      }).catch((error) => {
        this.props.setFormLoading({ isFormLoading: false });
        const alert = {
          visible: true,
          header: 'Error',
          content: error.message,
          type: 'error',
          autoDismiss: true,
          autoDismissInterval: 5000
        };
        this.props.setAlertDetails({ alert });
      });
    }
  }

  _renderFlightFound() {
    return (
      <div className="row m-4 airportDetailsRow" id="airportDetailsRow">
        <div className="col-sm-12 col-md-12 airlineContainer" id="airlineContainer">
          <div className="ui card" style={{ width: "100%" }}>
            <div className="content">
              <div>
                <span className="pull-left">
                  Updated: {this.state.updatedTime}
                </span>
                <span className="pull-right">
                  <button type="button" className="no-borders" style={{background: 'transparent', border: 'none'}} onClick={this._handleOnClickSearchFlight}><i className="fa fa-refresh" aria-hidden="false"></i></button>
                </span>
              </div>
            </div>
            <div className="content">
              <div className="ui sub header" style={{textAlign: "center"}}>
                {this.state.flight.flightDate}
              </div>
            </div>
            <div className="content">
              <h5 style={{margin:'7.5px 0'}}>
                {this.state.flight.departureAirport} to {this.state.flight.arrivalAirport}
              </h5>
              <div className="ui sub header">
                Status: <span style={{color: this.state.flight.statusColor, padding:'5px'}}>{this.state.flight.status}</span><br/>
                Departure: <span style={{color:PRIMARY_GREEN ,padding:'5px'}}>{this.state.flight.departureTime}</span><br/>
                Arrival: <span style={{color:PRIMARY_GREEN ,padding:'5px'}}>{this.state.flight.arrivalTime}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderFlightNotFound() {
    return (
      <div className="row m-4 airportDetailsRow" id="airportDetailsRow">
        <div className="col-sm-12 col-md-12 airlineContainer" id="airlineContainer">
          <div className="ui card" style={{ width: "100%" }}>
            <div className="content">
              <div>
                <span className="pull-left">
                  Updated: {this.state.updatedTime}
                </span>
                <span className="pull-right">
                  <button type="button" className="fa fa-refresh" style={{background: 'transparent', border: 'none'}} onClick={this._handleOnClickSearchFlight} aria-hidden="false"></button>
                </span>
              </div>
            </div>
            <div className="ui sub header" style={{textAlign: "center"}}>
              {this.state.flight.flightDate}
            </div>
            <div className="content">
              <h5 style={{textAlign: "center"}}>{FLIGHT_STATUS_NOT_FOUND}</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }


  render() {
    let airportForm = null;
    let flightInfoGrid = null;
    if (this.props.airportChecked) {
      airportForm = <form className='ui form payment-details-form' id='payment-details'>
        <div className="row m-4 airportDetailsRow" id="airportDetailsRow">
          <div className="col-sm-12 col-md-12 airlineContainer" id="airlineContainer">
            <Dropdown
              placeholder='Choose Airline'
              fluid
              search
              selection
              value={this.props.carrier}
              className='dropdown ui fluid transparent only-bottom-border'
              onChange={ this._handleAirlineChange }
              options={ this.state.airlinesList }
            />
          </div>
        </div>
        <div className="row m-4 airportDetailsRow" id="airportDetailsRow">
          <div className="col-sm-12 col-md-12 dropoff-container">
            <input type='number'
                   name='flightNumber'
                   id='flightNumber'
                   className='input ui fluid transparent only-bottom-border'
                   placeholder='Flight Number'
                   value={ this.state.flight.number }
                   onChange={ this._handleFlightNumberChange }/>
              <button type="button" id="submit-flight" className="ui blue button strongblue" style={{ margin: '10px'}} onClick={ this._handleOnClickSearchFlight }>
                <i className="fa fa-search" style={{ marginRight: '5px'}} aria-hidden="true"></i>
                Search Flight
              </button>
          </div>
        </div>
      </form>
    }

   if (this.state.checked && !stringUtils.isBlank(this.state.flight.status) && this.state.flight.status !== 'Error') {
      flightInfoGrid = this._renderFlightFound();
    }
    else if (this.state.checked && this.state.flight.status === 'Error'){
      flightInfoGrid = this._renderFlightNotFound();
    }

    return(
    <div>
      {airportForm}
      {flightInfoGrid}
    </div>)
  }
}

Airport.propTypes = {
  rideDate: PropTypes.string.isRequired,
  carrier: PropTypes.string,
  airportChecked: PropTypes.string,
  flightNumber: PropTypes.number,
  setFormLoading: PropTypes.func,
  setRideDetails: PropTypes.func.isRequired,
  rideDetails: PropTypes.object.isRequired,
  setAlertDetails: PropTypes.func.isRequired,
  flightFound: PropTypes.func
};
export default Airport;
