import React from 'react';
import PropTypes from 'prop-types';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import CustomDatePicker from '../fields/CustomDatePicker';
import CustomTimePicker from '../fields/CustomTimePicker';
import {RIDE_TYPES} from '../../constants/RideTypes';
import {NUMBER_OF_PASSENGERS} from '../../constants/NumberOfPassengers';
import {VEHICLE_DETAILS} from '../../constants/WidgetSteps';
import {DURATION_TYPES} from '../../constants/DurationTypes';
import * as gtmConstants from '../../constants/GtmConstants';
import {push} from '../../utils/gtmUtil';
import Airport from "../Airport";
import _ from 'lodash';

const autocompletionRequest = {
  types: ['geocode','establishment'],
  location: { lat: 36.163218, lng: -115.169129 },
  radius: 38000
};

class RideDetailsForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.rideDetails,
      isPickUpValid: false,
      isDropOffValid: false,
      airportChecked: false,
      enableOffers: false
    };

    this.onNumberOfPassengersChanged = this.onNumberOfPassengersChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeRideType = this.onChangeRideType.bind(this);
    this.onChangeDuration = this.onChangeDuration.bind(this);
  }

  componentDidMount() {
    $(document).ready(() => {
      $("#pickup-container input").attr("id", "pickUpLocation");
      $("#dropoff-container input").attr("id", "dropOffLocation");
      $("#pickUpLocation").attr("name", "pickUpLocation");
      $("#dropOffLocation").attr("name", "dropOffLocation");
      $("#pickUpDate").attr("required","required");
      $("#pickUpTime").attr("required","required");
      $("#rideType").attr("required","required");
      $("#numberOfPassengers").attr("required","required");
      $("#duration").attr("required", "required");
      $("#rideDetails").validate({
        highlight: function(element) {
          if(element.id === 'pickUpLocation' || element.id === 'dropOffLocation' || element.id === 'pickUpTime' || element.id === 'pickUpDate'){
            $("#"+element.id).css("border-bottom", "1px solid #c00");
          }
        },
        unhighlight: function(element) {
          if(element.id === 'pickUpLocation' || element.id === 'dropOffLocation' || element.id === 'pickUpTime' || element.id === 'pickUpDate'){
            $("#"+element.id).css("border-bottom", "1px solid lightgray");
          }
        }
      });

      $("#dropOffLocation").keyup(()=> {
        if($("#dropoff-container div div").length === 0 ) {
          this.setState({
            ...this.state,
            isDropOffValid:false
          });
        }
      });

      $("#pickUpLocation").keyup(()=> {
        if($("#pickup-container div div").length === 0) {
          this.setState({
            ...this.state,
            isPickUpValid:false
          });
        }
      });

      if (this.props.rideDetails.duration) {
        $("#durationSelectDefault").hide();
        $("#durationSelectEdit").show();
      } else if (!this.props.rideDetails.duration) {
        $("#durationSelectDefault").show();
        $("#durationSelectEdit").hide();
      }
      this.checkType(this.props.rideDetails.rideType);

      $(window).click(() => {
        if(!this.state.isDropOffValid && $("#dropOffLocation").val().length >0 && !this.props.rideDetails.duration){
          $("#dropOffLocation").css("border-bottom", "1px solid #c00");
        }
        if(!this.state.isPickUpValid && $("#pickUpLocation").val().length >0){
          $("#pickUpLocation").css("border-bottom", "1px solid #c00");
        }
      });
    });

    push(gtmConstants.GTM_PAGE_RIDE_DETAILS, gtmConstants.GTM_PAGE_TITLE_RIDE_DETAILS);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      const isPickUpValid = (newProps.rideDetails.fromCoordinates[0] >= -90 && newProps.rideDetails.fromCoordinates[0] <=90 && newProps.rideDetails.fromCoordinates[1] >= -180 && newProps.rideDetails.fromCoordinates[0] <=180);
      const isDropOffValid = (newProps.rideDetails.toCoordinates[0] >= -90 && newProps.rideDetails.toCoordinates[0] <=90 && newProps.rideDetails.toCoordinates[1] >= -180 && newProps.rideDetails.toCoordinates[0] <=180);
      const isAirport = newProps.rideDetails.flight.number != '' || this.state.airportChecked ? true : false;
      let newState = {
        ...newProps.rideDetails,
        isPickUpValid,
        isDropOffValid,
        airportChecked: isAirport
      };
      this.setState(newState);

    }

    if (!newProps.rideDetails.duration) {
      $("#durationSelectDefault").show();
      $("#durationSelectEdit").hide();
    }
  }

  onSubmit(e) {
    /** TODO: find the way to remove the message ''the field is required' after fill out the date and time again, because you have
     to do it twice to disappear it.
     TODO: fix the bouncing effect when the jquery validations appear, so it shouldn't move the rest of elements into the bottom
     TODO: try to remove the warnings as soon as possible
     **/
    if(this.state.airportChecked && this.props.rideDetails.flight.number != ""){      
      this.submitForm(e);
    }else if(!this.state.airportChecked) {
      this.submitForm(e);
    }

  }

  submitForm(e){
    if (this.props.rideDetails.rideType === 'Destination') {
      // TODO: [$("#rideDetails").valid() &&] this line now breaks the code with the new responsive styles.
      if (this.state.isPickUpValid && this.state.isDropOffValid) {
        this.props.setRideDetails(this.state);
        this.props.setCurrentStep({currentStep: VEHICLE_DETAILS});
      }
      e.preventDefault();
    } else if (this.props.rideDetails.rideType === 'Hourly') {
      if (this.state.isPickUpValid) {
        this.props.setRideDetails(this.state);
        this.props.setCurrentStep({currentStep: VEHICLE_DETAILS});
      }
      e.preventDefault();
    }
  }

  enableButton = () => this.setState({ enableOffers: true });

  onNumberOfPassengersChanged(event){
    let newState = {
      ...this.state,
      numberOfPassengers: parseInt(event.target.value),
    };
    this.setState(newState);
    this.props.setRideDetails(newState);
  }

  onChangeRideType(event){
    if(event.target.value === 'Hourly') {
      $("#dropoffRow").hide();
      $("#durationRow").show();

      let newState = {
        ...this.state,
        rideType: event.target.value,
        toAddress: '',
        toCoordinates: []
      };

      $("#dropOffLocation").hide();
      this.setState(newState);
      this.props.setRideDetails(newState);
    }

    if(event.target.value === 'Destination') {
      $("#durationRow").hide();
      $("#dropoffRow").show();

      let newState = {
        ...this.state,
        duration: null,
        rideType: event.target.value,
      };

      $("#dropOffLocation").show();
      this.setState(newState);
      this.props.setRideDetails(newState);
    }
  }

  onChangeDuration(event){
    let newState = {
      ...this.state,
      duration: event.target.value,
    };
    this.setState(newState);
    this.props.setRideDetails(newState);
  }

  onChangeNumberOfPassengers(event){
    let newState = {
      ...this.state,
      numberOfPassengers: event.target.value,
    };
    this.setState(newState);
    this.props.setRideDetails(newState);
  }

  checkType(type){
    if (type === 'Destination') {
      $("#durationRow").hide();
      $("#dropoffRow").show();
    } else if (type === 'Hourly') {
      $("#durationRow").show();
      $("#dropoffRow").hide();
    }
  }

  render() {
    return (
    <div className="p-2">
      <meta meta name="viewport" content=
        "width=device-width, user-scalable=no" />
        <h1 className="ui header formHeader">Book a Ride</h1>
        <div className="col-sm-12">
          <form className="ui form ride-form">
            <div className="row m-4 col-md-12 rideDropdownsContainer">
              <div className="col-sm-12 col-md-4 rideTypes" id="rideTypes" >
                <select className="dropdown ui fluid transparent no-borders" onChange={this.onChangeRideType} id="rideType" name="rideType" defaultValue={ this.props.rideDetails.rideType }>
                  { RIDE_TYPES.map((r) => {
                    return(<option key={ r } value={r}>{r}</option>)
                  })}
                </select>
              </div>
              <div className="col-sm-12 col-md-4 numberPassenger" id="numberPassenger">
                <select className="dropdown ui fluid transparent no-borders" onChange={this.onNumberOfPassengersChanged} id="numberOfPassengers" name="numberOfPassengers" defaultValue={ this.props.rideDetails.numberOfPassengers }>
                  { NUMBER_OF_PASSENGERS.map((val, index) => {
                    return(<option key={ index + 1 } value={index+1}>{val}</option>)
                  })}
                </select>
              </div>
              <div className="col-sm-12 col-md-4">&nbsp;</div>
            </div>
            <div className="row m-4 rideLocationsInput">
              <div className="col-sm-12 col-md-12 pickup-container" id="pickup-container">
                <GooglePlacesAutocomplete
                  onSelect={ (response) => {
                    geocodeByAddress(response.description)
                      .then(results => getLatLng(results[0]))
                      .then(({ lat, lng }) => {
                        let newState = {
                          ...this.state,
                          fromAddress: response.description,
                          fromCoordinates: [lat, lng],
                          isPickUpValid:true,
                          airportChecked: response.types[0] === 'airport' ? true : false
                        };
                        this.setState(newState);
                        this.props.setPickUpLocation({ fromAddress: response.description, fromCoordinates: [lat, lng] });
                      });
                  }}
                  placeholder='Pickup location'
                  required={true}
                  inputClassName="ui fluid transparent only-bottom-border no-bounce"
                  initialValue={ this.state.fromAddress }
                  autocompletionRequest={ autocompletionRequest }
                />
              </div>
            </div>
            <div className="row m-4 rideLocationsInput" id="dropoffRow">
              <div className="col-sm-12 col-md-12 dropoff-container" id="dropoff-container">
                <GooglePlacesAutocomplete
                  onSelect={ (response) => {
                    geocodeByAddress(response.description)
                      .then(results => getLatLng(results[0]))
                      .then(({ lat, lng }) => {
                        let newState = {
                          ...this.state,
                          toAddress: response.description,
                          toCoordinates: [lat, lng],
                          isDropOffValid:true
                        };
                        this.setState(newState);
                        this.props.setDropOffLocation({ toAddress: response.description, toCoordinates: [lat, lng] });
                      });
                  }}
                  placeholder='Dropoff location'
                  inputClassName="ui fluid transparent only-bottom-border"
                  required={true}
                  initialValue={ this.state.toAddress }
                  autocompletionRequest={ autocompletionRequest }
                />
              </div>
            </div>

            <Airport
              rideDetails={ this.props.rideDetails }
              carrier={ this.props.rideDetails.flight.carrier }
              flightNumber={ this.props.rideDetails.flight.number }
              setRideDetails={ this.props.setRideDetails }
              setAlertDetails={ this.props.setAlertDetails }
              airportChecked={ this.state.airportChecked }
              rideDate={ _.replace(JSON.stringify(this.props.rideDetails.pickUpDate), /"/g, "") }
              setFormLoading={ this.props.setFormLoading }
              flightFound = { this.enableButton }
              />

            <div className="row mt-5 ml-4 mr-4 pickupDateTimeRow">
              <div className="col-sm-12 col-md-12 pickupDateTimeLabel">
                Pickup date & time
              </div>
            </div>
            <div className="row ml-4 mr-4 mb-5 rideDateContainer">
              <div className="col-sm-12 col-md-6 rideDateSelect">
                <CustomDatePicker setPickUpDate={ this.props.setPickUpDate } defaultValue={ this.props.rideDetails.pickUpDate }/>
              </div>
              <div className="col-sm-12 col-md-6 rideHourSelect">
                <CustomTimePicker setPickUpTime={ this.props.setPickUpTime } defaultValue={ this.props.rideDetails.pickUpTime }/>
              </div>
            </div>
            <div className="row m-4 durationRow" id="durationRow">
              <div className="col-sm-12 col-md-12 durationContainer">
                <select className="dropdown ui fluid transparent only-bottom-border" onChange={this.onChangeDuration} id="durationSelectDefault" name="durationSelectDefault" required>
                  <option className="selectPlaceholder" id="durationOptionDefault" disabled selected>Select Duration</option>
                  { DURATION_TYPES.map((r) => {
                    return(<option key={ r } value={r}>{ r + ' hour(s)'}</option>)
                  })}
                </select>

                <select className="dropdown ui fluid transparent only-bottom-border" onChange={this.onChangeDuration} id="durationSelectEdit" name="durationSelectEdit" required>
                  <option className="selectPlaceholder" id="durationOptionEdit" selected >{ this.props.rideDetails.duration + ' hour(s)'}</option>
                  { DURATION_TYPES.map((r) => {
                    return(<option key={ r } value={r}>{ r + ' hour(s)'}</option>)
                  })}
                </select>
              </div>
            </div>
            <div className="row m-4 btnRow">
              <div className="col-sm-12 col-md-12 btnContainer">
                <button type="submit" disabled={this.state.airportChecked && !this.state.enableOffers ? true : false} className="ui blue button strongblue" id="submit-ride" onClick={ this.onSubmit } tabIndex="0">Show Offers</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

RideDetailsForm.propTypes = {
  rideDetails: PropTypes.object.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setRideDetails: PropTypes.func.isRequired,
  setPickUpLocation: PropTypes.func.isRequired,
  setDropOffLocation: PropTypes.func.isRequired,
  setPickUpDate: PropTypes.func.isRequired,
  setPickUpTime: PropTypes.func.isRequired,
  setAlertDetails: PropTypes.func.isRequired,
  setFormLoading: PropTypes.func.isRequired,
};

export default RideDetailsForm;
