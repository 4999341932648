import React from 'react';
import PropTypes from 'prop-types';
const mapsStaticUtil = require('../../utils/mapsStaticUtil');
import { combineAndAdd, formatTime } from "../../utils/dateUtil";
import { Card, Icon, Image } from "semantic-ui-react";
import { RIDE_DETAILS } from "../../constants/WidgetSteps";

class RideDetailsCard extends React.Component {

  constructor(props) {
    super(props);
    this.onEditClicked = this.onEditClicked.bind(this);
  }

  onEditClicked() {
    this.props.setCurrentStep({ currentStep: RIDE_DETAILS });
    this.props.setEstimatedDuration({ estimatedDuration: null });
    const newVehicleDetails = {
      ...this.props.vehicleDetails,
      serviceLevels: []
    }
    let newRideDetails = {};
    if(this.props.rideDetails.flight.number != ''){
      newRideDetails = {
        ...this.props.rideDetails,
        flight: {
          airline: '',
          carrier: ''
        }
      }
    }else{
      newRideDetails = {
        ...this.props.rideDetails,
        flight: {
          airline: '',
          carrier: '',
          number: ''
        }
      }
    }

    this.props.setVehicleDetails(newVehicleDetails);
    this.props.setRideDetails(newRideDetails);
  }

  render() {
    let dropOffTime = '';
    if (this.props.rideDetails.estimatedDuration && this.props.rideDetails.estimatedDuration > 0) {
      dropOffTime = formatTime(combineAndAdd(this.props.rideDetails.pickUpDate, this.props.rideDetails.pickUpTime, this.props.rideDetails.estimatedDuration));
    }

    if (this.props.rideDetails.rideType === "Destination") {
      $("#dropOffLoc").show();
      $("#noDropOffLoc").hide();
    } else if (this.props.rideDetails.rideType === 'Hourly') {
      $("#noDropOffLoc").show();
      $("#dropOffLoc").hide();
    }

    const description = <div style={{ padding: '10px' }}>
      <div className='mb-4' style={{ marginBottom: '10px', width: '100%' }}>
        <Image fluid src={ mapsStaticUtil.getStaticMapUrl({ markers: [
            {/*color: 'black',*/ point: this.props.rideDetails.fromCoordinates, label: 'Pickup'},
            {/*color: 'black',*/ point: this.props.rideDetails.toCoordinates, label: 'Dropoff'}
        ] })} />
      </div>

      <div className='row'>
        <div className='col-sm-1 col-md-1'>
          <Icon name='circle' />
        </div>
        <div className='col-sm-4 detailsCardLabel'>
          <span className="text-md-left font-weight-bold">Pickup</span>
        </div>
        <div className='col-sm-3 col-md-3 detailsCardTime'>
          <span className="text-md-left font-weight-bold">{ this.props.rideDetails.pickUpTime.toUpperCase() }</span>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-1 col-md-1'>
          {/*<Icon name='ellipsis vertical' />*/}
          &nbsp;
        </div>
        <div className='col-sm-8 col-md-8 detailsCardLocation'>
          <span className="text-md-left">{ this.props.rideDetails.fromAddress }</span>
        </div> <br/>
        <div className='col-sm-3 col-md-3'>
          &nbsp;
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-1 col-md-1'>
          {/*<Icon name='ellipsis vertical' />*/}
          &nbsp;
        </div>
        <div className='col-sm-8 col-md-8'>
          &nbsp;
        </div>
        <div className='col-sm-3 col-md-3'>
          &nbsp;
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-1 col-md-1'>
          <Icon name='square' />
        </div>
        <div className='col-sm-4 detailsCardLabel'>
          <span className="text-md-left font-weight-bold">Dropoff</span>
        </div>
        <div className='col-sm-3 col-md-3 detailsCardTime'>
          <span className="text-md-left font-weight-bold">{ dropOffTime.toUpperCase() }</span>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-1 col-md-1'>
          <i aria-hidden='true' className='icon' />
        </div>
        <div className='col-sm-8 col-md-8 detailsCardLocation'>
          <span className="text-md-left" id='dropOffLoc'>{ this.props.rideDetails.toAddress }</span>
          <span className="text-md-left" id='noDropOffLoc'>As Directed</span>
        </div>
        <div className='col-sm-3 col-md-3'>
          &nbsp;
        </div>
      </div>
    </div>;
    const editButton = <button className="ui secondary basic button edit-button-margin-top" onClick={ this.onEditClicked }>Edit</button>;
    return (
      <Card
        header='Ride details'
        description={ description }
        style={{ margin: '0 auto' }}
        extra={ editButton }
        className="ride-details-cards-container"
      />
    );
  }
}

RideDetailsCard.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  setEstimatedDuration: PropTypes.func.isRequired,
  setRideDetails: PropTypes.func.isRequired,
  rideDetails: PropTypes.object.isRequired,
  vehicleDetails: PropTypes.object.isRequired,
  setVehicleDetails: PropTypes.func.isRequired
};

export default RideDetailsCard;
