import React from 'react';
import PropTypes from 'prop-types';
import {
  combineDateAndTime,
  normalDateToBookingConfirmedDate,
} from '../../utils/dateUtil';
import { KaptynDataService } from '../../dataservices/KaptynDataService';
import { hasActiveSession, getUserId  } from '../../utils/cookieUtil';
import { PASSENGER_DETAILS, VEHICLE_DETAILS } from '../../constants/WidgetSteps';
import PaymentCardsManager from "../PaymentCardsManager";
import * as gtmConstants from '../../constants/GtmConstants';
import {push} from '../../utils/gtmUtil';

class PaymentDetailsForm extends React.Component {

  constructor(props) {
    super(props);
    this.getPaymentCards = this.getPaymentCards.bind(this);
    this.onAddSubmit = this.onAddSubmit.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onBookNowSubmit = this.onBookNowSubmit.bind(this);
    this.getUserId = this.getUserId.bind(this);
    this.state = {
      bookingConfirmedModalOpen: false
    }
  }

  componentDidMount() {
    $('.PhoneInputInput').attr('name', 'phone');
    // eslint-disable-next-line no-unused-vars
    $.validator.addMethod('validMonth', function(value, element) {
      return Number(value) >= 1 && Number(value) <= 12;
    }, 'This month is not valid');
    // eslint-disable-next-line no-unused-vars
    $.validator.addMethod('validYear', function(value, element) {
      const date = new Date();
      return Number(value) >= date.getUTCFullYear();
    }, 'This year is not valid');
    $('#payment-details').validate({
      rules: {
        cardHolder: {
          required: true
        },
        number: {
          required: true
        },
        expMonth: {
          required: true,
          minlength: 2,
          validMonth: true
        },
        expYear: {
          required: true,
          minlength: 4,
          validYear: true
        },
        cvc: {
          required: true
        }
      }
    });

    this.getPaymentCards();

    if (hasActiveSession()) {
      $('#username-logged-container').toggle();
    }

    push(gtmConstants.GTM_PAGE_PAYMENT_DETAILS, gtmConstants.GTM_PAGE_TITLE_PAYMENT_DETAILS);
  }

  getUserId() {
    let userId = null;
    if (this.props.conciergeUserId) {
      userId = this.props.conciergeUserId;
    } else if (this.props.guestUserId) {
      userId = this.props.guestUserId;
    }
    return userId;
  }

  onAddSubmit(e) {
    const paymentDetailsForm = $("#payment-details");
    if (paymentDetailsForm.valid()) {
      if (hasActiveSession() || this.props.continueAsGuest) {
        const userId = this.getUserId();
        const cardHolder = paymentDetailsForm.find('input[name="cardHolder"]').val();
        const number = paymentDetailsForm.find('input[name="number"]').val();
        const expMonth = paymentDetailsForm.find('input[name="expMonth"]').val();
        const expYear = paymentDetailsForm.find('input[name="expYear"]').val();
        const cvc = paymentDetailsForm.find('input[name="cvc"]').val();

        const cardPayload = {
          name: cardHolder,
          number,
          exp_month: expMonth,
          exp_year: expYear,
          cvc
        };

        this.props.setFormLoading({ isFormLoading: true });

        // TODO: move the key below to a configuration file
        // eslint-disable-next-line no-undef
        Stripe.setPublishableKey(`${ process.env.STRIPE_PUBLISHABLE_KEY }`);

        // eslint-disable-next-line no-undef
        Stripe.card.createToken(cardPayload, (status, response) => {
          if (response.error) {
            const alert = {
              visible: true,
              header: 'Error',
              content: response.error.message,
              type: 'error',
              autoDismiss: true,
              autoDismissInterval: 5000
            };
            this.props.setAlertDetails({ alert });
            this.props.setFormLoading({ isFormLoading: false });
          } else {
            const dataService = new KaptynDataService();
            dataService.createPaymentCard(userId, response.id).
              // eslint-disable-next-line no-unused-vars
            then((result) => {
              paymentDetailsForm.find('input[name="cardHolder"]').val('');
              paymentDetailsForm.find('input[name="number"]').val('');
              paymentDetailsForm.find('input[name="expMonth"]').val('');
              paymentDetailsForm.find('input[name="expYear"]').val('');
              paymentDetailsForm.find('input[name="cvc"]').val('');
              this.props.setFormLoading({ isFormLoading: false });
              this.getPaymentCards();
            }).
            catch((error) => {
              const alert = {
                visible: true,
                header: 'Error',
                content: error.message,
                type: 'error',
                autoDismiss: true,
                autoDismissInterval: 5000
              };
              this.props.setAlertDetails({ alert });
              this.props.setFormLoading({ isFormLoading: false });
            });
          }
        })
      } else {
        this.props.setModalState(true);
      }
    }
    e.preventDefault();
  }

  onBack() {
    this.props.setCurrentStep({ currentStep: this.props.isConcierge || this.props.continueAsGuest ? PASSENGER_DETAILS : VEHICLE_DETAILS });
  }

  onBookNowSubmit(e) {
    const hasPrimaryCard = this.hasPrimaryCard();
    let extrasParsed = {};
    if (hasPrimaryCard) {
      if (hasActiveSession() || this.props.continueAsGuest) {
        const group = this.props.vehicleDetails.group;
        const startTime = combineDateAndTime(this.props.rideDetails.pickUpDate, this.props.rideDetails.pickUpTime);
        const from = this.props.rideDetails.fromAddress;
        const to = this.props.rideDetails.toAddress;
        const startLoc = this.props.rideDetails.fromCoordinates;
        const destLoc = this.props.rideDetails.toCoordinates;
        const passengers = this.props.rideDetails.numberOfPassengers;
        const serviceLevel = this.props.vehicleDetails.serviceLevel;
        const userId = this.getUserId();
        const rider = {
          rider: userId ? userId : getUserId(),
          from,
          startLoc,
          to,
          destLoc
        };
        const riders = [rider];

        const extras = {
          names: [],
          values: []
        };

        const estimatedTime = this.props.rideDetails.estimatedDuration;

        if (this.props.rideDetails.flight.airportChecked) {
          extras.names.push('Airport');
          extras.names.push('Airline');
          extras.names.push('Flight#');
          extras.values.push('');
          extras.values.push(this.props.rideDetails.flight.carrier);
          extras.values.push(this.props.rideDetails.flight.number);
          extrasParsed.Airline = this.props.rideDetails.flight.carrier;
          extrasParsed["Flight#"] = this.props.rideDetails.flight.number;
        }

        this.props.setFormLoading({ isFormLoading: true });

        const dataService = new KaptynDataService();
        dataService.createRide(group, startTime, riders, passengers, serviceLevel, estimatedTime, extras, extrasParsed).
        then((result) => {
          this.props.setFormLoading({ isFormLoading: false });
          /** if the HTTP status is 200, it will open the popup and retrieve the expected params
           on the popup's message **/
          this.props.setModalStateBookingConfirmed(true);
          this.props.setBookingConfirmedDetails({
            scheduledDate: normalDateToBookingConfirmedDate(this.props.rideDetails.pickUpDate),
            reservationNumber: result.reservationNumber,
            userEmail: result.riders[0].rider.email
          });
        }).
        catch((error) => {
          const alert = {
            visible: true,
            header: 'Error',
            content: error.message,
            type: 'error',
            autoDismiss: true,
            autoDismissInterval: 5000
          };
          this.props.setAlertDetails({ alert });
          this.props.setFormLoading({ isFormLoading: false });
        });
      } else {
        this.props.setModalState(true);
      }
    } else {
      const alert = {
        visible: true,
        header: 'Warning',
        content: 'Please add a primary card in order to continue.',
        type: 'warning',
        autoDismiss: true,
        autoDismissInterval: 5000
      };
      this.props.setAlertDetails({ alert });
    }
    e.preventDefault();
  }

  sleeper(ms) {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }

  getPaymentCards() {
    this.props.setPaymentDetails({
      ...this.props.paymentDetails,
      loadingPaymentCards: true
    });
    this.props.getPaymentCards(this.getUserId());
  }

  hasPrimaryCard() {
    const paymentCards =  this.props.paymentDetails.paymentCards;
    if (paymentCards.length > 0) {
      for (let i = 0; i < paymentCards.length; i++) {
        const paymentCard = paymentCards[i];
        if (!paymentCard.expired && !paymentCard.failed && paymentCard.primary) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    return (
      <div className='p-4'>
        <meta meta name="viewport" content=
          "width=device-width, user-scalable=no" />
        <h1 className='ui header paymentHeader'>Payment Details</h1>
        <div className='payMethodsContainer'>
          <h6><span className="font-weight-bold">Payment cards</span></h6>
          <PaymentCardsManager paymentCards={ this.props.paymentDetails.paymentCards }
                               loadingPaymentCards={ this.props.paymentDetails.loadingPaymentCards }
                               userId={ this.getUserId() }
                               getPaymentCards={ this.getPaymentCards }
                               setFormLoading={ this.props.setFormLoading }
                               setAlertDetails={ this.props.setAlertDetails } />
        </div>
        <div className='mt-4'>
          <h6><span className="font-weight-bold">Add new card</span></h6>
          <form className='ui form payment-details-form' id='payment-details'>
            <div className='row mr-1 mb-3 ml-1'>
              <div className='col cardHolder'>
                <input type='text' name='cardHolder' id='cardHolder' className='form-control' placeholder='Cardholder Name'/>
              </div>
            </div>
            <div className='row mr-1 mb-3 ml-1'>
              <div className='col cardNumber'>
                <input type='text' name='number' id='number' className='form-control' placeholder='Card Number' required/>
              </div>
            </div>
            <div className='row mr-1 mb-3 ml-1'>
              <div className='col cardDetails'>
                <input type='text' name='expMonth' id='expMonth' className='form-control' placeholder='MM' required/>
              </div>
              <div className='col cardDetails'>
                <input type='text' name='expYear' id='expYear' className='form-control' placeholder='YYYY' required/>
              </div>
              <div className='col cardDetails'>
                <input type='password' name='cvc' id='cvc' className='form-control' placeholder='CVC' required/>
              </div>
              <div className='col cardDetails'>
                <button type='button' className='ui blue button add-payment-card' id='add-payment-card' onClick={ this.onAddSubmit }>Add</button>
              </div>
            </div>
            <div className='mt-5 mr-4 ml-1'>
              <div className='col-md-12 paymentBtnContainer'>
                <div className='btn-group btn-group-justified ui two buttons ' role='group' aria-label='Basic example'>
                  <button type='button' className='large ui black basic button col-md-4 goBackBtn' id='go-back-payment' tabIndex='0' onClick={ this.onBack }>Go back</button>
                  <button type='submit' className='large ui blue icon right labeled button col-md-6 ml-4 continueBtn' onClick={ this.onBookNowSubmit } id='submit-passengers-details'>
                    <i aria-hidden='true' className='right chevron icon'></i>
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

PaymentDetailsForm.propTypes = {
  isConcierge: PropTypes.bool.isRequired,
  conciergeUserId: PropTypes.string,
  setCurrentStep: PropTypes.func.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  setPaymentDetails: PropTypes.func.isRequired,
  rideDetails: PropTypes.object.isRequired,
  vehicleDetails: PropTypes.object.isRequired,
  passengerDetails: PropTypes.object.isRequired,
  getPaymentCards: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  setModalStateBookingConfirmed: PropTypes.func.isRequired,
  setBookingConfirmedDetails: PropTypes.func.isRequired,
  setFormLoading: PropTypes.func.isRequired,
  setAlertDetails: PropTypes.func.isRequired,
  guestUserId: PropTypes.string,
  continueAsGuest: PropTypes.bool.isRequired
};

export default PaymentDetailsForm;
