import React from 'react';
import PropTypes from 'prop-types';
import InternationalPhoneNumber from "../fields/InternationalPhoneNumber";
import { hasActiveSession  } from '../../utils/cookieUtil';
import { PAYMENT_DETAILS, VEHICLE_DETAILS } from "../../constants/WidgetSteps";
import { KaptynDataService } from "../../dataservices/KaptynDataService";
import * as gtmConstants from '../../constants/GtmConstants';
import {push} from '../../utils/gtmUtil';

class PassengerDetailsForm extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.passengerDetails !== newProps.passengerDetails) {
      $("#firstname").val(newProps.passengerDetails.name);
      $("#lastname").val(newProps.passengerDetails.lastname);
      $("#email").val(newProps.passengerDetails.email);
      $("#phone").val(newProps.passengerDetails.phone);
    }
  }

  componentDidMount() {
    $("#firstname").val(this.props.passengerDetails.name);
    $("#lastname").val(this.props.passengerDetails.lastname);
    $("#email").val(this.props.passengerDetails.email);
    $("#phone").val(this.props.passengerDetails.phone);

    $("#passenger-details").validate({
      rules: {
        firstname: {
          required: true
        },
        lastname: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true
        }
      }
    });

    push(gtmConstants.GTM_PAGE_PASSENGER_DETAILS, gtmConstants.GTM_PAGE_TITLE_PASSENGER_DETAILS);
  }

  onBack() {
    this.props.setCurrentStep({ currentStep: VEHICLE_DETAILS });
  }

  onSubmit(e) {
    const passengerDetailsForm = $("#passenger-details");
    if (passengerDetailsForm.valid() && this.props.tempDetails.passengerPhoneNumber) {
      if ((this.props.isConcierge && hasActiveSession()) || this.props.continueAsGuest) {
        const company = passengerDetailsForm.find('input[name="company"]').val();
        const firstName = passengerDetailsForm.find('input[name="firstname"]').val();
        const lastName = passengerDetailsForm.find('input[name="lastname"]').val();
        const email = passengerDetailsForm.find('input[name="email"]').val();
        const phone = this.props.tempDetails.passengerPhoneNumber;

        this.props.setFormLoading({ isFormLoading: true });

        const dataService = new KaptynDataService();
        dataService.createUser(firstName, lastName, email, phone).
        then((user) => {
          if (this.props.continueAsGuest) {
            this.props.setGuestUserId({ guestUserId: user.id });
          } else {
            this.props.setConciergeUserId({ conciergeUserId: user.id });
          }
          this.props.setPassengerDetails({
            company,
            name: firstName,
            lastname: lastName,
            email,
            phone
          });
          this.props.setFormLoading({ isFormLoading: false });
          this.props.setCurrentStep({ currentStep:PAYMENT_DETAILS });
        }).
        catch((error) => {
          const alert = {
            visible: true,
            header: 'Error',
            content: error.message,
            type: 'error',
            autoDismiss: true,
            autoDismissInterval: 5000
          };
          this.props.setAlertDetails({ alert });
          this.props.setFormLoading({ isFormLoading: false });
        });

      } else {
        this.props.setModalState(true);
      }
    }
    e.preventDefault();
  }

  render() {
    return (
      <div className="p-2">
        <meta meta name="viewport" content=
          "width=device-width, user-scalable=no" />
        <h1 className="ui header formHeader">Passenger Details</h1>
        <form className="ui form passenger-details-form" id="passenger-details">
          {/*<div className="row m-4">*/}
          {/*    <div className="col">*/}
          {/*      <input type="text" id="company" name="company" className="form-control" placeholder="Company (optional)"/>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className="row m-4 passengerNameRow">
              <div className="col passengerFirstName">
                <input type="text" id="firstname" name="firstname" className="form-control" placeholder="First name" required/>
              </div>
              <div className="col PassengerLastName">
                <input type="text" id="lastname" name="lastname" className="form-control" placeholder="Last name" required/>
              </div>
          </div>
          <div className="row m-4 passengerEmailRow">
            <div className="col passengerEmail">
              <input type="text" id="email" name="email" className="form-control" placeholder="Email" required/>
            </div>
          </div>
          <div className="row mt-5 ml-4 mr-4 countryCodeRow">
            <div className="col-sm-12 col-md-12 countryCode">
              Country code
            </div>
          </div>
          <div className="row ml-4 mr-4 mb-5 phoneRow" id="parent-phone">
            <div className="col-sm-12 col-md-12 phoneContainer" id="phone-number-container">
              <InternationalPhoneNumber setPassengerPhoneNumber={ this.props.setPassengerPhoneNumber } initialValue={ this.props.passengerDetails.phone }/>
            </div>
            <div className="col-sm-12 col-md-8">&nbsp;</div>
          </div>
          <div className='m-2'>
            <div className="col-md-12 btnContainer">
              <div className="btn-group ui two buttons " role="group" aria-label="Basic example">
                <button type="button" className="large ui black basic button col-md-4 goBackBtn" id="go-back-passengers" tabIndex="0" onClick={ this.onBack }>Go back</button>
                <button type="submit" className="large ui blue icon right labeled button col-md-6 ml-4 continueBtn" onClick={ this.onSubmit } id="submit-passenger-details">
                  <i aria-hidden="true" className="right chevron icon"></i>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

PassengerDetailsForm.propTypes = {
  setModalState: PropTypes.func.isRequired,
  passengerDetails: PropTypes.object.isRequired,
  setPassengerPhoneNumber: PropTypes.func.isRequired,
  setPassengerDetails: PropTypes.func.isRequired,
  setConciergeUserId: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  tempDetails: PropTypes.object.isRequired,
  setFormLoading: PropTypes.func.isRequired,
  setAlertDetails: PropTypes.func.isRequired,
  isConcierge: PropTypes.bool.isRequired,
  continueAsGuest: PropTypes.bool.isRequired,
  setGuestUserId: PropTypes.func.isRequired
};

export default PassengerDetailsForm;
