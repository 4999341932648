import React, { Component } from 'react'
import {Button, Header, Grid, Modal, Icon} from 'semantic-ui-react'
import InternationalPhoneNumber from "../fields/InternationalPhoneNumber";
import { setCookies } from '../../utils/cookieUtil';
import { KaptynDataService } from '../../dataservices/KaptynDataService';
import PropTypes from 'prop-types';
import Alert from "../Alert";
import {PASSENGER_DETAILS, PAYMENT_DETAILS, VEHICLE_DETAILS} from "../../constants/WidgetSteps";
import * as gtmConstants from '../../constants/GtmConstants';
import {push} from '../../utils/gtmUtil';

export default class SignUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSignUp: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    $('#signup-details').validate({
      rules:{
        firstName:{
          required: true
        },
        lastName:{
          required: true
        },
        email:{
          required: true,
          email:true
        },
        phone:{
          required: true
        },
        password:{
          required: true,
          minlength : 8
        },
        password_confirm : {
          required: true,
          minlength : 8,
          equalTo : '#password'
        }
      },
      messages:{
        password_confirm: 'The passwords don\'t match'
      }
    });
  }

  handleClose() {
    this.setState({ showSignUp: false }, this.handleModalStateChange);
  }

  handleModalStateChange = () => {
    if (this.state.showSignUp) {
      push(gtmConstants.GTM_PAGE_SIGN_UP, gtmConstants.GTM_PAGE_TITLE_SIGN_UP);
    }
  };

  onSubmit(e) {
    if ($("#signup-details").valid()) {
      const signUpDetails = $("#signup-details");
      const payload = {
        firstName: signUpDetails.find('input[name="firstName"]').val(),
        lastName: signUpDetails.find('input[name="lastName"]').val(),
        email: signUpDetails.find('input[name="email"]').val(),
        password: signUpDetails.find('input[name="password"]').val(),
        phone: signUpDetails.find('.PhoneInputInput').val()
      };
      this.props.setSessionLoading({ isSessionLoading: true });
      const dataService = new KaptynDataService();
      dataService.signUp(payload).then((result) => {
        setCookies(
          result.user.id,
          result.token,
          result.user.email,
          result.user.firstName,
          result.user.lastName,
          result.user.phone);

        // If this is not the concierge scenario, use the details of the
        // current user in session as the passenger details.
        if (!this.props.isConcierge) {
          this.props.setPassengerDetails({
            name: result.user.firstName,
            lastname: result.user.lastName,
            email: result.user.email,
            phone: result.user.phoneNumbers[0].phoneNumber
          });
        }

        if (this.props.continueAsGuest) {
          this.props.setInitialState();
        } else if (this.props.currentStep === VEHICLE_DETAILS) {
          this.props.setCurrentStep({ currentStep: this.props.isConcierge ? PASSENGER_DETAILS : PAYMENT_DETAILS });
          this.props.setModalState(false);
        }

        this.props.setSessionLoading({ isSessionLoading: false });

        this.handleClose();
      }).catch((error)=>{
        const alert = {
          visible: true,
          header: 'Error',
          content: error.message,
          type: 'error',
          autoDismiss: true,
          autoDismissInterval: 5000
        };
        this.props.setAlertDetails({ alert });
        this.props.setSessionLoading({ isSessionLoading: false });
      });

    }
    e.preventDefault();
  }
  render() {
    let alert = null;
    if (this.props.alertDetails.signUpAlertDetails.visible) {
      alert = <div style={{ position: 'relative' }}>
        <Alert alertDetails={ this.props.alertDetails.signUpAlertDetails } setAlertDetails={ this.props.setAlertDetails } />
        &nbsp;
      </div>
    }
    let button = <Button onClick={() => this.setState({ showSignUp: true }, this.handleModalStateChange)} fluid basic color='black' id="register-new-account"><span>Register New Account</span></Button>;
    if (!this.props.basicButton) {
      button = <Button onClick={() => this.setState({ showSignUp: true }, this.handleModalStateChange)} fluid primary color='white' id="register-new-account-blue"><span>Register New Account</span><Icon className="arrow right" style={{ float:'right' }}/></Button>;
    }
    return (
      <Modal trigger={ button }
             open={ this.state.showSignUp }
             onClose={ this.handleClose }
             style={{ height: 'auto', minHeight: '600px', position: 'relative', margin: '140px 33%' }}
             size='fullscreen'
             className="col-md-4"
             closeIcon>
        <Modal.Content>
          <meta meta name="viewport" content=
            "width=device-width, user-scalable=no" />
          <Grid>
            <Grid.Row style={{ margin:'auto' }}>
              <Grid.Column className="signUpContainer">
                <Header as='h1'>
                  Sign up
                </Header>
                { alert }
                <form className="ui form signup-details-form" id="signup-details">
                  <div className="row m-2">
                    <div className="col singInFirstName">
                      <input type="text" name="firstName" className="form-control" placeholder="First name" required/>
                    </div>
                    <div className="col singInLastName">
                      <input type="text" name="lastName" className="form-control" placeholder="Last name" required/>
                    </div>
                  </div>
                  <div className="row m-2">
                    <div className="col">
                      <input type="text" name="email" className="form-control" placeholder="Email" required/>
                    </div>
                  </div>
                  <div className="row mt-5 ml-2 mr-2">
                    <div className="col-sm-12 col-md-12">
                      Country code
                    </div>
                  </div>
                  <div className="row ml-2 mr-2 mb-3" id="parent-phone-signup">
                    <div className="col-sm-12 col-md-12" id="phone-number-container">
                      <InternationalPhoneNumber setPassengerPhoneNumber={ this.props.setPassengerPhoneNumber }/>
                    </div>
                  </div>
                  <div className="row m-2">
                    <div className="col">
                      <input type="password" id="password" name="password" className="form-control" placeholder="Password" required/>
                    </div>
                  </div>
                  <div className="row m-2">
                    <div className="col">
                      <input type="password" id="password_confirm" name="password_confirm" className="form-control" placeholder="Confirm Password" required/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="btn-group ui two buttons " role="group" aria-label="Basic example">
                      <button type="submit" className="large ui blue icon right labeled button col-md-5 ml-4 submit-register-account" onClick={ this.onSubmit } id="submit-register-account">
                        <i aria-hidden="true" className="right chevron icon"></i>
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    )
  }
}

SignUp.propTypes = {
  isConcierge: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setPassengerDetails: PropTypes.func.isRequired,
  setPassengerPhoneNumber: PropTypes.func.isRequired,
  isSessionLoading: PropTypes.bool.isRequired,
  setSessionLoading: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  alertDetails: PropTypes.object.isRequired,
  setAlertDetails: PropTypes.func.isRequired,
  continueAsGuest: PropTypes.bool.isRequired,
  setInitialState: PropTypes.func.isRequired,
  basicButton: PropTypes.bool.isRequired
};
