export function setCookies(userId, token, email, firstName, lastName, phoneNumber) {
  _setCookie('userId', userId, 90);
  _setCookie('token', token, 90);
  _setCookie('email', email, 90);
  _setCookie('firstName', firstName, 90);
  _setCookie('lastName', lastName, 90);
  _setCookie('phoneNumber', phoneNumber,90);
}

export function destroyCookies() {
  localStorage.clear();
}

export function getUserId() {
  return _getCookie('userId') || null;
}

export function getToken() {
  return _getCookie('token') || null;
}

export function getEmail() {
  return _getCookie('email') || null;
}

export function getFirstName() {
  return _getCookie('firstName') || null;
}

export function getLastName() {
  return _getCookie('lastName') || null;
}

export function getFullName() {
  const firstName = _getCookie('firstName');
  const lastName = _getCookie('lastName');
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return null;
}

export function getPhoneNumber() {
  return _getCookie('phoneNumber') || null;
}

export function hasActiveSession() {
  return getToken() !== null;
}

// eslint-disable-next-line no-unused-vars
function _setCookie(name, value, days) {
  // const d = new Date();
  // d.setTime(d.getTime() + (days*24*60*60*1000));
  // const expires = "expires=" + d.toUTCString();
  localStorage.setItem(name, value);
}

function _getCookie(name) {
  if (name) {
    return localStorage.getItem(name);
  }
  else {
    return ''
  }
}
