export const ONE = '1 adult';
export const TWO = '2 adults';
export const THREE = '3 adults';
export const FOUR = '4 adults';
export const FIVE = '5 adults';
export const SIX = '6 adults';
export const SEVEN = '7 adults';
export const EIGHT = '8 adults';
export const NINE = '9 adults';
export const TEN = '10 adults';
export const NUMBER_OF_PASSENGERS = [ONE, TWO, THREE, FOUR, FIVE, SIX, SEVEN, EIGHT, NINE, TEN];
