import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";

class CustomDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.props.setPickUpDate({pickUpDate:this.state.startDate});
  }
  state = {
    startDate: this.props.defaultValue ? this.props.defaultValue : new Date()
  };

  handleChange = date => {
    this.setState({
      startDate: date
    });

    this.props.setPickUpDate({pickUpDate:date});
  };

  render() {
    return (
      <DatePicker
        selected={this.state.startDate}
        dateFormat="EEE, MMM d"
        onChange={this.handleChange}
        placeholderText="date"
        className={"ui fluid transparent only-bottom-border"}
        id="pickUpTime"
        name="pickUpTime"
      />
    );
  }
}

CustomDatePicker.propTypes = {
  defaultValue: PropTypes.string,
  setPickUpDate: PropTypes.func.isRequired
}

export default CustomDatePicker;
