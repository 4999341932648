import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

class Alert extends React.Component {

  constructor(props) {
    super(props);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    if (this.props.alertDetails.autoDismiss) {
      const autoDismissInterval = this.props.alertDetails.autoDismissInterval || 5000;
      setTimeout(this.handleDismiss, autoDismissInterval);
    }
  }

  handleDismiss() {
    const alert = {
      visible: false,
      header: '',
      content: '',
      type: '',
      autoDismiss: false,
      autoDismissInterval: 0
    };
    this.props.setAlertDetails({ alert });
  }

  render() {
    return (
      <Message
        onDismiss={ this.handleDismiss }
        header={ this.props.alertDetails.header }
        content={ this.props.alertDetails.content }
        success={ this.props.alertDetails.type === 'success' }
        error={ this.props.alertDetails.type === 'error' }
        warning={ this.props.alertDetails.type === 'warning' }
        info={ this.props.alertDetails.type === 'info' }
        visible={this.props.alertDetails.visible}
        size={ 'small' }
        className='alert'
      />
    );
  }
}

Alert.propTypes = {
  alertDetails: PropTypes.object.isRequired,
  setAlertDetails: PropTypes.func.isRequired
};

export default Alert;
