import React from 'react';
import PropTypes from 'prop-types';
import queryString from "query-string";
import {Container, Form} from "semantic-ui-react";
import _ from 'lodash';


import { KaptynDataService } from "../dataservices/KaptynDataService";
import Alert from "./Alert";

export class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    // TODO: Move this to constant file
    this.successMessage = "Password was successfully changed";

    this.state = {
      tempToken: "",
      newPassword: "",
      confirmPassword: "",
      bannerImage: "",
      redirectUrl: "",
      alertDetails: {
        type: "",
        content: "",
        visible: true,
        header: ""
      },
      passwordWasReset: false
    };
    this._onPasswordReset = this._onPasswordReset.bind(this);
    this._onLoginButtonPressed = this._onLoginButtonPressed.bind(this);
    this.setAlertDetails = this.setAlertDetails.bind(this);
  }

  componentDidMount() {
    const queryStringValues = queryString.parse(this.props.location.search);
    const tempToken = queryStringValues['temp_token'];
    const bannerImage = queryStringValues['banner_image'];
    const redirectUrl = queryStringValues['redirect_URL'];
    this.setState({tempToken});
    this.setState({bannerImage});
    this.setState({redirectUrl});
  }

  _onPasswordReset() {
    const service = new KaptynDataService();
    service.changePassword(this.state.tempToken, this.state.newPassword, this.state.confirmPassword)
      .then(() => {
        this._setSuccessAlert(this.successMessage);
        this.setState({passwordWasReset: true});
      })
      .catch((err) => {
        this._setErrorAlert(err.message);
      });
  }

  _onLoginButtonPressed() {
    window.location = this.state.redirectUrl;
  }

  setAlertDetails(details, callback) {
    const newAlertDetails = {...this.state.alertDetails, ...details};
    this.setState({ alertDetails: newAlertDetails }, callback);
  }

  _setErrorAlert(content, callback) {
    this.setAlertDetails({type: 'error', content: content, visible: true}, callback);
  }

  _setSuccessAlert(content, callback) {
    this.setAlertDetails({type: 'success', content: content, visible: true}, callback);
  }

  render() {
    let alert = null;
    let actionButton = null;
    let passwordField = null;
    let confirmPasswordField = null;

    if (!_.isEmpty(this.state.alertDetails.content)) {
      alert = (
          <Alert alertDetails={this.state.alertDetails} setAlertDetails={details => this.setAlertDetails(details.alert)}/>
      );
    }

    if (this.state.passwordWasReset) {
      actionButton = (
        <button type='button' className={'large ui green icon labeled button'} onClick={ this._onLoginButtonPressed }>
          <i aria-hidden="true" className="right chevron icon"></i>
          Login
        </button>
      )
    } else {
      passwordField = (
        <div className={'form-input-container row'}>
          <div className={'col'}>
            <input type={"password"}
                   className={"ui fluid transparent only-bottom-border no-bounce"}
                   placeholder={"New Password"}
                   onChange={event => this.setState({newPassword: event.target.value}) }/>
          </div>
        </div>
      );
      confirmPasswordField = (
        <div className={'form-input-container row'}>
          <div className={'col'}>
            <input type={"password"}
                   className={"ui fluid transparent only-bottom-border no-bounce"}
                   placeholder={"Confirm New Password"}
                   onChange={event => this.setState({confirmPassword: event.target.value}) }/>
          </div>
        </div>
      );
      actionButton = (
        <button type='button' className={'large ui blue icon labeled button'} onClick={ this._onPasswordReset }>
          <i aria-hidden="true" className="right chevron icon"></i>
          Change Password
        </button>
      );
    }

    return (
      <Container style={{ margin: '20px' }}>
          <div id={"_reset_password_container"}>
            <div className={"row page-header"}>
              <img className={"image_banner"} src={this.state.bannerImage}/>
            </div>
            <div className={"row alert-container"}>
              {alert}
            </div>
            <Form id={"_reset_password_form"} className={'ui form row'}>
              <div className={"form-title"}>
                <p> Reset your password </p>
              </div>
              { passwordField }
              { confirmPasswordField }
              <div className={'col-lg-6 col-md-8 form-button-container row'}>
                { actionButton }
              </div>
            </Form>
          </div>
      </Container>
    )
  }
}

ResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired
};

ResetPasswordPage.contextTypes = {
  router: PropTypes.object.isRequired,
};
