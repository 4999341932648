export const GTM_EVENT_PAGE_VIEW = 'Pageview';

export const GTM_PAGE_LOG_IN = '/loginModal';
export const GTM_PAGE_SIGN_UP = '/signUpModal';
export const GTM_PAGE_RIDE_DETAILS = '/rideDetailsPage';
export const GTM_PAGE_VEHICLE_DETAILS = '/vehicleDetailsPage';
export const GTM_PAGE_PASSENGER_DETAILS = '/passengerDetailsPage';
export const GTM_PAGE_PAYMENT_DETAILS = '/paymentDetailsPage';
export const GTM_PAGE_BOOKING_CONFIRMATION = '/bookingConfirmationModal';

export const GTM_PAGE_TITLE_SIGN_UP = 'Sign up';
export const GTM_PAGE_TITLE_LOG_IN = 'Welcome back! / Guest';
export const GTM_PAGE_TITLE_RIDE_DETAILS = 'Book a Ride';
export const GTM_PAGE_TITLE_VEHICLE_DETAILS = 'Select Vehicle';
export const GTM_PAGE_TITLE_PASSENGER_DETAILS = 'Passenger Details';
export const GTM_PAGE_TITLE_PAYMENT_DETAILS = 'Payment Details';
export const GTM_PAGE_TITLE_BOOKING_CONFIRMATION = 'Booking confirmed!';
