import React from 'react';
import PropTypes from 'prop-types';
import RideDetailsCard from './cards/RideDetailsCard';
import VehicleDetailsCard from './cards/VehicleDetailsCard';
import PassengerDetailsCard from './cards/PassengerDetailsCard';
import * as WidgetSteps from '../constants/WidgetSteps';
import { Map as GoogleMap } from 'google-maps-react';
import { Card } from 'semantic-ui-react'

const containerStyle = {
  position: 'absolute',
  width: '100%',
  minHeight: '600px',
  maxHeight: '600px'
};

class MapContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data : [
        {
          lat: this.props.rideDetails.fromCoordinates[0],
          lng: this.props.rideDetails.fromCoordinates[1]
        },
        {
          lat: this.props.rideDetails.toCoordinates[0],
          lng: this.props.rideDetails.toCoordinates[1]
        }
      ],
      map: null,
      // eslint-disable-next-line no-undef
      directionsService: new google.maps.DirectionsService(),
      // eslint-disable-next-line no-undef
      directionsDisplay: new google.maps.DirectionsRenderer(),
      durationStartLocMarker: null,
      currentLocation: { lat:36.1699412,lng:-115.1398296 }
    };
    this.handleMapReady = this.handleMapReady.bind(this);
    this.clearMarkers = this.clearMarkers.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.rideDetails.fromAddress === '' && newProps.rideDetails.toAddress === '') {
      this.state.directionsDisplay.set('directions', null);
    }
    if (this.props !== newProps && (this.props.rideDetails.fromAddress !== newProps.rideDetails.fromAddress || this.props.rideDetails.toAddress !== newProps.rideDetails.toAddress)) {
      let newState = {
        ...this.state
      };
      newState.data[0].lat = newProps.rideDetails.fromCoordinates[0];
      newState.data[0].lng = newProps.rideDetails.fromCoordinates[1];
      newState.data[1].lat = newProps.rideDetails.toCoordinates[0];
      newState.data[1].lng = newProps.rideDetails.toCoordinates[1];
      this.setState(newState);

      if (newProps.rideDetails.rideType === 'Destination') {
        this.clearMarkers(null);
        this.calculateAndDisplayRoute(this.state.map);
      } else if (newProps.rideDetails.rideType === 'Hourly') {
        this.state.directionsDisplay.set('directions', null);
        if (this.state.durationStartLocMarker != null) {
          this.clearMarkers();
        }
        this.hourlyRideMarker(this.state.map);
      }
    }
  }

  handleMapReady(mapProps, map) {
    let newState = {
      ...this.state,
      map
    };
    this.setState(newState);

    if (this.props.rideDetails.rideType === 'Destination') {
      this.calculateAndDisplayRoute(map);
    } else if (this.props.rideDetails.rideType === 'Hourly') {
      this.hourlyRideMarker(map);
    }
  }

  calculateAndDisplayRoute(map) {
    this.state.directionsDisplay.setMap(map);
    const waypoints = this.state.data.map(item => {
      return {
        location: { lat: item.lat, lng:item.lng },
        stopover: false
      }
    });

    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    if (!origin.lat || !origin.lng || !destination.lat || !destination.lng) return;

    this.state.directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: waypoints,
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
        this.state.directionsDisplay.setDirections(response);
      } else {
        const alert = {
          visible: true,
          header: 'Error',
          content: 'An error occurred calculating the route. Please try again.',
          type: 'error',
          autoDismiss: true,
          autoDismissInterval: 5000
        };
        this.props.setAlertDetails({ alert });
      }
    });
  }

  hourlyRideMarker(map) {
    let myLatLng = {lat: this.state.data[0].lat, lng: this.state.data[0].lng};
    // eslint-disable-next-line no-undef
    let marker = new google.maps.Marker({
      position: myLatLng,
      map: map
    });
    let newState = {
      ...this.state,
      durationStartLocMarker: marker
    };
    newState.durationStartLocMarker.setMap(map);
    this.setState(newState);
    map.setCenter(myLatLng);
  }


  clearMarkers() {
    let newState = {
      ...this.state,
      durationStartLocMarker: null
    };
    this.setState(newState);
    if (this.state.durationStartLocMarker) {
      this.state.durationStartLocMarker.setMap(null);
    }
  }

  render() {
    let card = '';
    if (this.props.currentStep === WidgetSteps.VEHICLE_DETAILS) {
      card = <RideDetailsCard setCurrentStep={ this.props.setCurrentStep }
                              setEstimatedDuration={ this.props.setEstimatedDuration }
                              rideDetails={ this.props.rideDetails }
                              vehicleDetails={ this.props.vehicleDetails }
                              setVehicleDetails={ this.props.setVehicleDetails }
                              setRideDetails={this.props.setRideDetails}/>;
    }
    else if (this.props.currentStep === WidgetSteps.PASSENGER_DETAILS) {
      card = <VehicleDetailsCard setCurrentStep={ this.props.setCurrentStep } vehicleDetails={ this.props.vehicleDetails }/>;
    }
    else if (this.props.currentStep === WidgetSteps.PAYMENT_DETAILS) {
      card = <PassengerDetailsCard setCurrentStep={ this.props.setCurrentStep } passengerDetails={ this.props.passengerDetails } rideDetails={ this.props.rideDetails } />
    }
    return (
      <div id='mapContainer'>
        <div className='map'>
          <GoogleMap
            google={ window.google }
            zoom={ 13 } // TODO: handle it through the props
            initialCenter={ this.state.currentLocation }
            onReady={ this.handleMapReady }
            containerStyle={ containerStyle }
          />
        </div>
        <div className="container" style={{ width:'100%' }}>
          <Card.Group centered>
            { card }
          </Card.Group>
        </div>
      </div>
    );
  }
}

MapContainer.propTypes = {
  google: PropTypes.object,
  zoom: PropTypes.number,
  center: PropTypes.array,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setEstimatedDuration: PropTypes.func.isRequired,
  rideDetails: PropTypes.object.isRequired,
  setRideDetails: PropTypes.func.isRequired,
  vehicleDetails: PropTypes.object.isRequired,
  setVehicleDetails: PropTypes.func.isRequired,
  passengerDetails: PropTypes.object.isRequired,
  setAlertDetails: PropTypes.func.isRequired
};

export default MapContainer;
