let STORE_REGISTRY = null;

export const storeRegistry = {

  register: (store) => {
    STORE_REGISTRY = store;
  },

  getStore: () => {
    return STORE_REGISTRY;
  },

};
