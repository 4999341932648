import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Image } from "semantic-ui-react";
import { VEHICLE_DETAILS } from "../../constants/WidgetSteps";
import BMW750Image from "../../images/cars/bmw-750.png";
import DefaultImage from "../../images/cars/default-car.png";
import LuxuryLargeSUVImage from "../../images/cars/luxury-large-suv.png";
import Model3Image from "../../images/cars/model-3.png";
import ModelSImage from "../../images/cars/model-s.png";
import ModelXImage from "../../images/cars/model-x.png";
import SprinterVanImage from "../../images/cars/sprinter-van.png";
import StretchLimoImage from "../../images/cars/stretch-limo.png";
import SUVImage from "../../images/cars/suv.png";
import {getCurrencyFormattedNumber} from "../../utils/numberFormatUtil";

const MODEL_3 = 'model 3';
const MODEL_S = 'model s';
const MODEL_X = 'model x';
const BMW_750 = 'bmw 750';
const LUXURY_LARGE_SUV = 'luxury large suv';
const SUV = 'xl';
const STRETCH_LIMO = 'stretch';
const SPRINTER_VAN = 'sprinter';

class VehicleDetailsCard extends React.Component {

  constructor(props) {
    super(props);
    this.onEditClicked = this.onEditClicked.bind(this);
  }

  onEditClicked() {
    this.props.setCurrentStep({ currentStep: VEHICLE_DETAILS });
  }

  _renderCarImage(name, description) {
    let image = DefaultImage;
    name = name ? name.toLowerCase() : '';
    description = description ? description.toLowerCase() : '';
    if (name.indexOf(MODEL_3) > -1 || description.indexOf(MODEL_3) > -1) {
      image = Model3Image;
    } else if (name.indexOf(MODEL_S) > -1 || description.indexOf(MODEL_S) > -1) {
      image = ModelSImage
    } else if (name.indexOf(MODEL_X) > -1 || description.indexOf(MODEL_X) > -1) {
      image = ModelXImage;
    } else if (name.indexOf(BMW_750) > -1 || description.indexOf(BMW_750) > -1) {
      image = BMW750Image;
    } else if (name.indexOf(LUXURY_LARGE_SUV) > -1 || description.indexOf(LUXURY_LARGE_SUV) > -1) {
      image = LuxuryLargeSUVImage;
    } else if (name.indexOf(SUV) > -1 || description.indexOf(SUV) > -1) {
      image = SUVImage;
    } else if (name.indexOf(STRETCH_LIMO) > -1 || description.indexOf(STRETCH_LIMO) > -1) {
      image = StretchLimoImage;
    } else if (name.indexOf(SPRINTER_VAN) > -1 || description.indexOf(SPRINTER_VAN) > -1) {
      image = SprinterVanImage;
    }
    return <Image src={ image } size='medium' centered/>
  }

  render() {
    const description = <div style={{ padding: '10px'}}>
      <div className='row' id="vehicleDetailsCard">
        <div className='mb-4 mt-4 ml-6' style={{ margin: '50px' }}>
            { this._renderCarImage(this.props.vehicleDetails.name, this.props.vehicleDetails.description) }
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 col-md-12'>
          <h1 className="card-title">{ this.props.vehicleDetails.rate ? getCurrencyFormattedNumber(this.props.vehicleDetails.rate) : 'Rate not available' }</h1>
          <div className='meta text-secondary'>
            <p className="text-lg-left"> { this.props.vehicleDetails.name }</p>
          </div>
        </div>
      </div>
      <br/>
      <div className='row'>
        <div className='col-sm-1 col-md-1'>
          <Icon name='car' size="large"/>
        </div>
        <div className='col-sm-9 col-md-9'>
          <p className="font-weight-bold">{ this.props.vehicleDetails.description }</p>
        </div>
      </div>
      <div className='col-sm-2 col-md-2'>
        &nbsp;
      </div>
    </div>;
    const editButton = <button className="ui secondary basic button" onClick={ this.onEditClicked }>Edit</button>;
    return (
      <Card
        header='Vehicle details'
        description={ description }
        style={{ margin: '0 auto' }}
        extra={ editButton }
        className="vehicle-card-container"
      />
    );
  }
}

VehicleDetailsCard.propTypes = {
  vehicleDetails: PropTypes.object.isRequired,
  setCurrentStep: PropTypes.func.isRequired
};

export default VehicleDetailsCard;
