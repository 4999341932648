import {GTM_EVENT_PAGE_VIEW} from "../constants/GtmConstants";

export function push(pagePath, pageTitle) {
  const env = `${ process.env.NODE_ENV }`;
  if (env === 'production') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': GTM_EVENT_PAGE_VIEW,
      'pagePath': pagePath,
      'pageTitle': pageTitle
    });
  }
}
