const _ = require('lodash');
const url = require('url');
import queryString from 'query-string';
// TODO: move this API key to a config file.
const MAPS_API_KEY = `${ process.env.GOOGLE_MAPS_API_KEY }`;
const MAPS_STATIC_HOST = 'maps.googleapis.com/maps/api/staticmap';

/**
 * @param options {{
 *     format: string
 *     waypoints: [[Number]] | String
 *     areWaypointsEncoded: Boolean,
 *     line_weight: Number,
 *     path_color: String,
 *     markers: [{
 *         color: String,
 *         point: [Number],
 *         label: undefined | String
 *     }],
 *     mapHeight: number
 *     mapWidth: number
 * }}
 * @returns {string}
 */
export function getStaticMapUrl(options) {
  options = _.defaults(options, {
    areWaypointsEncoded: false,
    format: 'png',
    line_weight: 5,
    path_color: '0x869AE7',
    markers: [],
    mapHeight: 250,
    mapWidth: 500
  });

  const searchParams = {};

  if (options.waypoints) {
    const pathWaypoints = options.areWaypointsEncoded ? `enc:${options.waypoints}` : _formatWaypoints(options.waypoints);

    const pathOptions = _formatParameters({
      color: options.path_color,
      weight: options.line_weight,
      points: pathWaypoints
    }, ['points']);

    searchParams['path'] = pathOptions;
  }

  let formattedMarkers = [];
  options.markers.forEach((marker) => {
    const parameters = {};
    parameters.color = marker.color;

    if (marker.label) {
      parameters.size = 'mid';
      parameters.label = marker.label.toUpperCase();
    } else {
      parameters.size = 'small';
    }

    // The point should be the last parameter added to the marker parameters
    parameters.point = marker.point;

    formattedMarkers.push(_formatParameters(parameters, ['point']));
  });
  searchParams['markers'] = formattedMarkers;
  searchParams['format'] = options.format;
  searchParams['size'] = `${options.mapWidth}x${options.mapHeight}`;
  searchParams['maptype'] = 'roadmap';
  searchParams['key'] = MAPS_API_KEY;
  searchParams['nocache'] = (new Date()).getTime();

  return url.format({
    host: MAPS_STATIC_HOST,
    protocol: 'https',
    search: queryString.stringify(searchParams)
  });
}

/**
 *
 * @param parameters {Object}
 * @param keysToOmit {[String]}
 * @returns {String}
 * @private
 */
function _formatParameters(parameters, keysToOmit) {
  return Object.keys(parameters)
    .map((key) => {
      if (keysToOmit.includes(key)) {
        return parameters[key].toString();
      }
      return `${key}:${parameters[key]}`;
    })
    .join('|');
}

/**
 *
 * @param {[[Number]]} waypoints
 * @returns {String}
 */
function _formatWaypoints(waypoints) {
  return waypoints
    .map(waypoint => `${waypoint[0]}, ${waypoint[1]}`)
    .join('|');
}
