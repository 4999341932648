import browser from 'bowser';
import _ from 'lodash';

/**
 *
 * @param options {{
 *   isConcierge: boolean
 * }}
 * @returns {Object}
 **/
export function getDeviceInfo(options) {
  options = _.defaults(options, {
    isConcierge: false
  });

  return {
    type: 'web',
      friendlyName: browser.osname,
      osVersion: browser.osversion,
      appName: options.isConcierge ? 'concierge' : 'widget',
      browser: {
      name: browser.name,
        version: browser.version
    }
  };
}
