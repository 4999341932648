import React, { Component } from 'react'
import moment from 'moment'
import { Header, Grid, Modal, Icon, Image } from 'semantic-ui-react'
const mapsStaticUtil = require('../../utils/mapsStaticUtil');
import PropTypes from "prop-types";
import {combineAndAdd, formatTime} from "../../utils/dateUtil";
import SignUp from "./SignUp";
import {push} from "../../utils/gtmUtil";
import * as gtmConstants from "../../constants/GtmConstants";
export default class BookingConfirmed extends Component {
  state = {
    modalOpen: this.props.modalStateBookingConfirmed
  };
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      this.setState({
        modalOpen: newProps.modalStateBookingConfirmed
      }, this.handleModalStateChange);
    }
  }
  handleOpen = () => this.setState({modalOpen: true}, this.handleModalStateChange);
  handleClose = () => {
    window.location.reload();
  };
  handleModalStateChange = () => {
    if (this.state.modalOpen) {
      push(gtmConstants.GTM_PAGE_BOOKING_CONFIRMATION, gtmConstants.GTM_PAGE_TITLE_BOOKING_CONFIRMATION);
      this.trackSojern(moment(this.props.rideDetails.pickUpDate).format('YYYY-MM-DD'),
        moment(combineAndAdd(this.props.rideDetails.pickUpDate, this.props.rideDetails.pickUpTime, this.props.rideDetails.estimatedDuration)).format('YYYY-MM-DD'),
        'USD',
        this.props.vehicleDetails.rate,
        this.props.bookingConfirmedDetails.reservationNumber);
    }
  };
  trackSojern(rd1, rd2, rcu, rp, rconfno) {
    if (rconfno) {
      let params = {"rd1": rd1, "rd2": rd2, "rcu": rcu, "rp": rp, "rconfno": rconfno};

      /* Please do not modify the below code. */
      let cid = [];
      let paramsArr = [];
      let cidParams = [];
      let pl = document.createElement('script');
      let defaultParams = {"vid": "car", "et": "rc"};
      for (let key in defaultParams) {
        params[key] = defaultParams[key];
      }
      for (let key in cidParams) {
        cid.push(params[cidParams[key]]);
      }
      params.cid = cid.join('|');
      for (let key in params) {
        paramsArr.push(key + '=' + encodeURIComponent(params[key]))
      }
      pl.type = 'text/javascript';
      pl.async = true;
      pl.src = 'https://beacon.sojern.com/pixel/p/292612?f_v=v6_js&p_v=1&' + paramsArr.join('&');
      document.getElementById('sojern').appendChild(pl);
    }
  }
  onSubmit(e) {
    e.preventDefault();
  }
  render() {
    let dropOffTime = '';
    if (this.props.rideDetails.estimatedDuration && this.props.rideDetails.estimatedDuration > 0) {
      dropOffTime = formatTime(combineAndAdd(this.props.rideDetails.pickUpDate, this.props.rideDetails.pickUpTime, this.props.rideDetails.estimatedDuration));
    }
    if (this.props.rideDetails.rideType === "Destination") {
      $("#dropOff").show();
      $("#asDirected").hide();
    } else if (this.props.rideDetails.rideType === 'Hourly') {
      $("#asDirected").show();
      $("#dropOff").hide();
    }
    let registerNewAccountText = null;
    let registerNewAccountBlock = null;
    if (this.props.continueAsGuest) {
      registerNewAccountBlock = <SignUp isConcierge={ this.props.isConcierge }
                                        currentStep={ this.props.currentStep }
                                        setCurrentStep={ this.props.setCurrentStep }
                                        setPassengerPhoneNumber={ this.props.setPassengerPhoneNumber }
                                        setPassengerDetails={ this.props.setPassengerDetails }
                                        isSessionLoading={ this.props.isSessionLoading }
                                        setSessionLoading={ this.props.setSessionLoading }
                                        setModalState={ this.props.setModalState }
                                        alertDetails={ this.props.alertDetails }
                                        setAlertDetails={ this.props.setSignUpAlertDetails }
                                        continueAsGuest={ this.props.continueAsGuest }
                                        setInitialState={ this.props.setInitialState }
                                        basicButton={ false } />;
      registerNewAccountText = <p>In the meantime, register today to save time on future bookings, view booking history, and more.</p>;
    }
    return (
      <Modal open={ this.state.modalOpen }
             onClose={ this.handleClose }
             style={{ width: '450px', height: 'auto', top: 'auto', left: 'auto', bottom: 'auto', right: 'auto' }}
             closeIcon
             size='fullscreen'
             className="col-md-6">
        <Modal.Content>
          <Grid columns={2}>
            <Grid.Row style={{ padding: '20px' }}>
              <Grid.Column className="bookingConfirmationContact">
                <Header as='h1'>
                  Booking confirmed!
                </Header><br/>
                <p>{ this.props.bookingConfirmedDetails.scheduledDate }</p>
                <p><strong>Reservation #</strong>{ this.props.bookingConfirmedDetails.reservationNumber } </p>
                <p>A booking confirmation email will be sent shortly to <b>{ this.props.passengerDetails.email }</b>.</p>
                { registerNewAccountText }
                <br/>
                { registerNewAccountBlock }
                <br/>
                <button type="button" className="medium ui black basic button col-md-5" id="go-back-booking-confirmed" tabIndex="0" onClick={ this.handleClose }>Close</button>
              </Grid.Column>
              <Grid.Column className='bookingConfirmationRideDetails'>
                <Header as='h1'>
                  <Header.Subheader style={{ marginTop: '10px', marginBottom: '10px' }}>
                    Ride details
                  </Header.Subheader>
                </Header>
                <div style={{ padding: '10px' }}>
                  <div className='mb-4' style={{ marginBottom: '10px', width: '100%' }}>
                    <Image src={ mapsStaticUtil.getStaticMapUrl({ markers: [
                        { point: this.props.rideDetails.fromCoordinates, label: 'Pickup' },
                        { point: this.props.rideDetails.toCoordinates, label: 'Dropoff' }
                      ] })} />
                  </div>
                  <div className='row'>
                    <div className='col-sm-1 col-md-1'>
                      <Icon name='circle' />
                    </div>
                    <div className='col-sm-8 col-md-8 detailsCardLabel'>
                      <span className="text-md-left font-weight-bold">Pickup</span>
                    </div>
                    <div className='col-sm-3 col-md-3 detailsCardTime'>
                      <span className="text-md-left font-weight-bold">{ this.props.rideDetails.pickUpTime ? this.props.rideDetails.pickUpTime.toUpperCase() : null }</span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-1 col-md-1'>
                      {/*<Icon name='ellipsis vertical' />*/}
                      &nbsp;
                    </div>
                    <div className='col-sm-8 col-md-8 detailsCardLocation'>
                      <span className="text-md-left">{ this.props.rideDetails.fromAddress }</span>
                    </div>
                    <div className='col-sm-3 col-md-3'>
                      &nbsp;
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-1 col-md-1'>
                      {/*<Icon name='ellipsis vertical' />*/}
                      &nbsp;
                    </div>
                    <div className='col-sm-8 col-md-8'>
                      &nbsp;
                    </div>
                    <div className='col-sm-3 col-md-3'>
                      &nbsp;
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-1 col-md-1'>
                      <Icon name='square' />
                    </div>
                    <div className='col-sm-8 col-md-8 detailsCardLabel'>
                      <span className="text-md-left font-weight-bold">Dropoff</span>
                    </div>
                    <div className='col-sm-3 col-md-3 detailsCardTime'>
                      <span className="text-md-left font-weight-bold">{ dropOffTime.toUpperCase() }</span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-1 col-md-1'>
                      <i aria-hidden='true' className='icon' />
                    </div>
                    <div className='col-sm-8 col-md-8 detailsCardLocation'>
                      <span className="text-md-left" id='dropOff'>{ this.props.rideDetails.toAddress }</span>
                      <span className="text-md-left" id='asDirected'>As Directed</span>
                    </div>
                    <div className='col-sm-3 col-md-3'>
                      &nbsp;
                    </div>
                    <div id="sojern"/>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    )
  }
}
BookingConfirmed.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setInitialState: PropTypes.func.isRequired,
  modalStateBookingConfirmed: PropTypes.bool.isRequired,
  rideDetails: PropTypes.object.isRequired,
  vehicleDetails: PropTypes.object.isRequired,
  bookingConfirmedDetails: PropTypes.object.isRequired,
  passengerDetails: PropTypes.object.isRequired,
  continueAsGuest: PropTypes.bool.isRequired,
  isConcierge: PropTypes.bool.isRequired,
  setPassengerDetails: PropTypes.func.isRequired,
  setPassengerPhoneNumber: PropTypes.func.isRequired,
  isSessionLoading: PropTypes.bool.isRequired,
  setSessionLoading: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  alertDetails: PropTypes.object.isRequired,
  setSignUpAlertDetails: PropTypes.func.isRequired,
};
