import React from 'react';
import PropTypes from 'prop-types';
import WidgetContainer from '../containers/WidgetContainer';
import RideDetailsForm from './forms/RideDetailsForm';
import VehicleDetailsForm from './forms/VehicleDetailsForm';
import PassengerDetailsForm from './forms/PassengerDetailsForm';
import PaymentDetailsForm from './forms/PaymentDetailsForm';
import * as WidgetSteps from '../constants/WidgetSteps';
import Map from './Map';
import '../../jquery';
import '../../jquery-validations';
import queryString from 'query-string'
import Login from "./modals/Login";
import BookingConfirmed from "./modals/BookingConfirmed";
import Alert from './Alert';
import {getToken, destroyCookies, getFullName} from "../utils/cookieUtil";
import { Button, Dropdown, Icon, Dimmer, Loader } from "semantic-ui-react";
import { KaptynDataService } from "../dataservices/KaptynDataService";

export class DisplayWidgetPage extends React.Component {

  constructor(props) {
    super(props);
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    const queryStringValues = queryString.parse(this.props.location.search);
    this.props.setApiKey({ apiKey: queryStringValues.api_key });
    this.props.setIsConcierge({ isConcierge: Boolean(queryStringValues.concierge) });
  }

  onLogout(e) {
    this.props.setFormLoading({ isFormLoading: true });
    const dataService = new KaptynDataService();
    dataService.logout().then((result) => {
      if (result.success) {
        destroyCookies();
        this.props.setFormLoading({ isFormLoading: false });
        this.props.setInitialState();
      }
    }).catch((error) => {
      const alert = {
        visible: true,
        header: 'Error',
        content: error.message,
        type: 'error',
        autoDismiss: true,
        autoDismissInterval: 5000
      };
      this.props.setFormAlertDetails({ alert });
    });
    e.preventDefault();
  }

  render() {
    let form;
    if (this.props.currentStep === WidgetSteps.RIDE_DETAILS) {
      form = <RideDetailsForm setCurrentStep={ this.props.setCurrentStep }
                              rideDetails={ this.props.rideDetails }
                              setRideDetails={ this.props.setRideDetails }
                              setPickUpLocation={ this.props.setPickUpLocation }
                              setDropOffLocation={ this.props.setDropOffLocation }
                              setPickUpDate={ this.props.setPickUpDate }
                              setPickUpTime={ this.props.setPickUpTime }
                              setAlertDetails={ this.props.setFormAlertDetails }
                              setFormLoading={ this.props.setFormLoading }/>
    } else if (this.props.currentStep === WidgetSteps.VEHICLE_DETAILS) {
      form = <VehicleDetailsForm isConcierge={ this.props.isConcierge }
                                 setCurrentStep={ this.props.setCurrentStep }
                                 vehicleDetails={ this.props.vehicleDetails }
                                 getServiceLevels={ this.props.getServiceLevels }
                                 setVehicleDetails={ this.props.setVehicleDetails }
                                 setPassengerDetails={ this.props.setPassengerDetails }
                                 setEstimatedDuration={ this.props.setEstimatedDuration }
                                 setModalState={ this.props.setModalState }
                                 continueAsGuest={ this.props.continueAsGuest }
                                 setAlertDetails={ this.props.setFormAlertDetails } />
    } else if (this.props.currentStep === WidgetSteps.PASSENGER_DETAILS) {
      form = <PassengerDetailsForm passengerDetails={ this.props.passengerDetails }
                                   setPassengerDetails={ this.props.setPassengerDetails }
                                   setPassengerPhoneNumber={this.props.setPassengerPhoneNumber}
                                   setCurrentStep={this.props.setCurrentStep}
                                   setPaymentDetails={ this.props.setPaymentDetails }
                                   tempDetails={ this.props.tempDetails }
                                   setConciergeUserId={ this.props.setConciergeUserId }
                                   setModalState={ this.props.setModalState }
                                   setFormLoading={ this.props.setFormLoading }
                                   setAlertDetails={ this.props.setFormAlertDetails }
                                   continueAsGuest={ this.props.continueAsGuest }
                                   isConcierge={ this.props.isConcierge }
                                   setGuestUserId={ this.props.setGuestUserId } />
    } else {
      form = <PaymentDetailsForm isConcierge={ this.props.isConcierge }
                                 conciergeUserId={ this.props.conciergeUserId }
                                 setCurrentStep={this.props.setCurrentStep}
                                 rideDetails={ this.props.rideDetails }
                                 vehicleDetails={ this.props.vehicleDetails }
                                 passengerDetails={ this.props.passengerDetails }
                                 paymentDetails={ this.props.paymentDetails }
                                 setPaymentDetails={ this.props.setPaymentDetails }
                                 getPaymentCards={ this.props.getPaymentCards }
                                 setModalState={ this.props.setModalState }
                                 setModalStateBookingConfirmed={ this.props.setModalStateBookingConfirmed }
                                 setBookingConfirmedDetails={ this.props.setBookingConfirmedDetails }
                                 setFormLoading={ this.props.setFormLoading }
                                 setAlertDetails={ this.props.setFormAlertDetails }
                                 guestUserId={ this.props.guestUserId }
                                 continueAsGuest={ this.props.continueAsGuest } />
    }
    let alert = null;
    if (this.props.alertDetails.formAlertDetails.visible) {
      alert = <div>
          <Alert alertDetails={ this.props.alertDetails.formAlertDetails } setAlertDetails={ this.props.setFormAlertDetails } />
        </div>;
    }
    let sessionControls = null;
    if (getToken()) {
      sessionControls = <Dropdown item
          text={ getFullName() }
          square='true'
          button
          id="full-name-logged"
          size='large'
          style={{ margin:'12px', position:'sticky', float:'right', zIndex:1000}}>
        <Dropdown.Menu>
          <Dropdown.Item id="logout-btn" onClick={ this.onLogout }>
            <Icon name="shutdown"></Icon>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>;
    } else {
      sessionControls = <Button id="log-register-btn"
        className="square"
        floated='right'
        style={{ position: 'sticky', zIndex:1000, margin:'10px', backgroundColor:'white' }}
        onClick={() => {
          this.props.setModalState(true);
        }}>
        Log in / Register
      </Button>
    }
    return (
      <div>
        { alert }
        <div className='row contentRow' style={{ width: '100%', maxHeight: '550px' }}>
          <div className='col-sm-12 col-md-4 rideFormContainer'>
            <Dimmer active={ this.props.isFormLoading } inverted>
              <Loader />
            </Dimmer>
            <div className="col-md-12">
              { sessionControls }
            </div>
            { form }
          </div>
          <div className='col-sm-12 col-md-8 mapFormContainer'>
            <Map currentStep={ this.props.currentStep }
                 setCurrentStep={ this.props.setCurrentStep }
                 setEstimatedDuration={ this.props.setEstimatedDuration }
                 rideDetails={ this.props.rideDetails }
                 setRideDetails={ this.props.setRideDetails }
                 vehicleDetails={ this.props.vehicleDetails }
                 setVehicleDetails={ this.props.setVehicleDetails }
                 passengerDetails={ this.props.passengerDetails }
                 setPassengerDetails={ this.props.setPassengerDetails }
                 setPaymentDetails={ this.props.setPaymentDetails }
                 setAlertDetails={ this.props.setFormAlertDetails }
            />
          </div>
          <Login isConcierge={ this.props.isConcierge }
                 setPassengerPhoneNumber={ this.props.setPassengerPhoneNumber }
                 setCurrentStep={ this.props.setCurrentStep }
                 setPassengerDetails={ this.props.setPassengerDetails }
                 setPaymentDetails={ this.props.setPaymentDetails }
                 currentStep={ this.props.currentStep }
                 setModalState={ this.props.setModalState }
                 modalState={ this.props.modalState }
                 isSessionLoading={ this.props.isSessionLoading }
                 setSessionLoading={ this.props.setSessionLoading }
                 alertDetails={ this.props.alertDetails }
                 setAlertDetails={ this.props.setLoginAlertDetails }
                 setSignUpAlertDetails={ this.props.setSignUpAlertDetails }
                 setContinueAsGuest={ this.props.setContinueAsGuest }
                 continueAsGuest={ this.props.continueAsGuest }
                 setInitialState={ this.props.setInitialState }/>
          <BookingConfirmed setInitialState={ this.props.setInitialState }
                            currentStep={ this.props.currentStep }
                            setCurrentStep={ this.props.setCurrentStep }
                            bookingConfirmedDetails={ this.props.bookingConfirmedDetails }
                            modalStateBookingConfirmed={ this.props.modalStateBookingConfirmed }
                            rideDetails={ this.props.rideDetails }
                            vehicleDetails={ this.props.vehicleDetails }
                            passengerDetails={ this.props.passengerDetails }
                            continueAsGuest={ this.props.continueAsGuest }
                            isConcierge={ this.props.isConcierge }
                            setPassengerPhoneNumber={ this.props.setPassengerPhoneNumber }
                            setPassengerDetails={ this.props.setPassengerDetails }
                            isSessionLoading={ this.props.isSessionLoading }
                            setSessionLoading={ this.props.setSessionLoading }
                            setModalState={ this.props.setModalState }
                            alertDetails={ this.props.alertDetails }
                            setSignUpAlertDetails={ this.props.setSignUpAlertDetails }/>
        </div>
      </div>
    );
  }
}

DisplayWidgetPage.propTypes = {
  setApiKey: PropTypes.func.isRequired,
  isConcierge: PropTypes.bool.isRequired,
  conciergeUserId: PropTypes.string,
  setIsConcierge: PropTypes.func.isRequired,
  setConciergeUserId: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  rideDetails: PropTypes.object.isRequired,
  setRideDetails: PropTypes.func.isRequired,
  setPickUpLocation: PropTypes.func.isRequired,
  setDropOffLocation: PropTypes.func.isRequired,
  setPickUpDate: PropTypes.func.isRequired,
  setPickUpTime: PropTypes.func.isRequired,
  setEstimatedDuration: PropTypes.func.isRequired,
  vehicleDetails: PropTypes.object.isRequired,
  getServiceLevels: PropTypes.func.isRequired,
  setVehicleDetails: PropTypes.func.isRequired,
  passengerDetails: PropTypes.object.isRequired,
  setPassengerDetails: PropTypes.func.isRequired,
  setPassengerPhoneNumber: PropTypes.func.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  setPaymentDetails: PropTypes.func.isRequired,
  getPaymentCards: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setInitialState: PropTypes.func.isRequired,
  bookingConfirmedDetails: PropTypes.object.isRequired,
  setBookingConfirmedDetails: PropTypes.func.isRequired,
  setModalStateBookingConfirmed: PropTypes.func.isRequired,
  modalStateBookingConfirmed: PropTypes.bool.isRequired,
  isFormLoading: PropTypes.bool.isRequired,
  setFormLoading: PropTypes.func.isRequired,
  isSessionLoading: PropTypes.bool.isRequired,
  setSessionLoading: PropTypes.func.isRequired,
  tempDetails: PropTypes.object.isRequired,
  alertDetails: PropTypes.object.isRequired,
  setFormAlertDetails: PropTypes.func.isRequired,
  setLoginAlertDetails: PropTypes.func.isRequired,
  setSignUpAlertDetails: PropTypes.func.isRequired,
  continueAsGuest: PropTypes.bool.isRequired,
  setContinueAsGuest: PropTypes.bool.isRequired,
  guestUserId: PropTypes.string,
  setGuestUserId: PropTypes.func.isRequired
};

const WidgetPage
  = WidgetContainer()( // eslint-disable-line new-cap
  DisplayWidgetPage
);

export default WidgetPage;

